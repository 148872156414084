import { VisibilityOutlined } from '@mui/icons-material';
import { Box, Button, capitalize, Chip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import DashboardContainer from '../../../components/DashboardContainer'
import { getAllApplications } from '../../../redux/api/internal';
import { getColor } from '../../../util';
import moment from 'moment'

const AllApplications = () => {
    const location = useLocation();
    const history = useHistory();
    const [ selectedStatus, setSelectedStatus ] = useState('')
    const [ applications, setApplications ] = useState([])
    const [ fetching, setFetching ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        // { field: 'sn', headerName: 'S/N', width: 100, valueGetter: (params) => (params?.api?.getRowIndex(params?.row?.id)  || 0) + 1 },
        { 
            field: 'sn', headerName: 'S/N', width: 80,
            renderCell: (params) => (
                <p
                    style={{ margin: 'auto' }}
                >
                    {params.api.getRowIndex(params?.row?.id) + 1}
                </p>
            )
        },
        {
            field: 'id', headerName: 'Quick Actions', width: 150, sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        title='View Application'
                        onClick={() => history.push(`/internal/applications/${params?.row?.id}`)}
                    >
                        <VisibilityOutlined />
                    </IconButton>
                </div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <div><Chip style={{ fontSize: 12 }} size='small' color={getColor(params?.row?.status || '')} label={capitalize(params?.row?.status || '')} /></div>
            ),
        },
        { field: 'app_no', headerName: 'Application No.', width: 200 },
        { field: 'programme_name', headerName: 'Programme', width: 350 },
        { field: 'programme_category', headerName: 'Category', width: 350 },
        {
            field: 'created_at',
            headerName: 'Creation Date',
            width: 250,
            valueGetter: (params) => moment(params.row.created_at).format('DD/MM/YYYY - HH:MM:SS A'),
        },
    ];
    
    const init = async (status) => {
        try {
            setFetching(true);
            setSelectedStatus(status)
            const { data, status: stat } = await getAllApplications(status)
            setApplications(data);
            setSelectedStatus(capitalize(stat || ''))
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setFetching(false)
        }
    }

    const setStatus = (stat) => {
        switch(stat) {
            case 1:
                return 'Initiated';
            case 2:
                return 'Submitted';
            case 3:
                return '';
            case 4:
                return '';
            case 5:
                return '';
            case 6:
                return '';
            case 7:
                return '';
            case 8:
                return '';
            case 9:
                return '';
            default:
                return '';
        }
    }

    useEffect(() => {
        const stat = location?.search?.split('status=')[1] || '';
        init(stat)
        setSelectedStatus(setStatus(stat))
    }, [location])

    return (
        <DashboardContainer processing={fetching} pageTitle={`${selectedStatus || 'All'} Applications`}>
            <Box>
                <div style={{ height: 550, width: '100%', textAlign: 'center' }} className='data-table'>
                    <DataGrid
                        rows={applications}
                        columns={columns}
                        // pageSize={10}
                        // rowsPerPageOptions={[10]}
                        checkboxSelection={false}
                        disableSelectionOnClick
                    />
                </div>
            </Box>
        </DashboardContainer>
    )
}

export default AllApplications