import { LinearProgress } from '@mui/material'
import React from 'react'

const Loader = () => {
    return (
        <div className='loader'>
            <img src={`${process.env.PUBLIC_URL}/assets/images/aaipsds-logo.png`} alt="logo" />
            <LinearProgress />
        </div>
    )
}

export default Loader