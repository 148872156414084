import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { CreateOrEditUsersPage, RolesPrivilegesPage, UsersPage } from "../pages";
import Page404 from "../pages/Page404";
import { getPrivilegeStatus } from "../util";
import { EventsPage, NewsPage, EditNewsPage, StaffDashboardPage, GalleryPage, EditGalleryPage, AllApplicationsPage, ApplicationDetailsPage } from "../pages/staff";

const InternalRoutes = () => {
    const { path, url } = useRouteMatch();

    
    const RouteChecker = ({ component: Component, exact, id, ...rest }) => {
        return (
          <Route
            {...rest}
            render={(props) =>
              (getPrivilegeStatus(id) || id == '') ? <Component {...props} /> : <Redirect to={`${path}/not-found`} />
            }
            exact={exact}
          />
        );
    };

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${path}/dashboard`} />
            </Route>

            <RouteChecker exact path={`${path}/dashboard`} component={StaffDashboardPage} id="" />

            <RouteChecker exact path={`${path}/applications`} component={AllApplicationsPage} id="viewApplications" />
            <RouteChecker exact path={`${path}/applications/:appId`} component={ApplicationDetailsPage} id="viewApplications" />


            <RouteChecker exact path={`${path}/users`} component={UsersPage} id="accessUserManagement" />

            <RouteChecker exact path={`${path}/users/user`} component={CreateOrEditUsersPage} id="accessUserManagement" />
            <RouteChecker exact path={`${path}/users/user/:id`} component={CreateOrEditUsersPage} id="accessUserManagement" />
            
            <RouteChecker exact path={`${path}/users/roles_privileges`} component={RolesPrivilegesPage} id="accessUserManagement" />

            {/* <Route exact path={`${path}/website-resources/events`} component={EventsPage} />

            <Route exact path={`${path}/website-resources/news`} component={NewsPage} />
            <Route exact path={`${path}/website-resources/news/new`} component={EditNewsPage} />
            <Route exact path={`${path}/website-resources/news/:slug`} component={EditNewsPage} />

            <Route exact path={`${path}/website-resources/gallery`} component={GalleryPage} />
            <Route exact path={`${path}/website-resources/gallery/new`} component={EditGalleryPage} />
            <Route exact path={`${path}/website-resources/gallery/:slug`} component={EditGalleryPage} /> */}
            <Route exact path={`${path}/not-found`} component={Page404} />

            <Route path="**">
                <Page404 />
            </Route>
        </Switch>
    )
}

export default InternalRoutes
