import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Drawer as MuiDrawer, List, Typography, Divider, IconButton, Toolbar,
    CssBaseline, AppBar as MuiAppBar, Grid, Popover, Dialog, DialogContent, DialogActions, Button, ListItem, ListItemIcon, ListItemText, capitalize
} from '@mui/material';
import { Business, Numbers, Person, Menu, ExitToApp, AccountCircle, Mail } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import MenuAccordion from './common/MenuAccordion';
import { LIGHT_GREY, PRIMARY_COLOR } from '../styles/colors';
import Loader from './common/Loader';
import { bindActionCreators } from 'redux';
import { signOutUser } from '../redux/actions/userActions'
import { connect } from 'react-redux';
import Helmet from 'react-helmet'
import { getMenu } from '../routes/menuConfig'
import { useMediaQuery } from 'react-responsive'

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
  
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
  
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
  
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, md }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && md && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
  
const MenuDrawer = (props) => {
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open, md }) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        }),
    );
    return (
        props?.md ? <Drawer { ...props } /> : <MuiDrawer { ...props } />
    )

}


const DashboardContainer = ({ children, buttonComponent, pageTitle = '', prevTitle = '', processing = false, signOutUser, user }) => {
    const md = useMediaQuery({ query: '(min-width: 1200px)' })
    const xs = useMediaQuery({ query: '(max-width: 500px)' })

    const location = useLocation()
    const [open, setOpen] = useState(true);
    const [logoutDialog, setLogoutDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userPopover, setUserPopover] = useState(null);
    const [menuList, setMenulist] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };
  
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const toggleMenu = () => {
        setOpen(!open);
    };
    
    const getActiveLink = (link, check) => {
        return check ? Boolean(location.pathname.toLowerCase().indexOf(check.toLowerCase()) > -1) : Boolean(location.pathname.toLowerCase().indexOf(link.toLowerCase()) > -1)
    }
    
    useEffect(() => {
        setUserPopover([
            { name: user?.userData?.username || '', icon: <Person />, title: 'Username' },
            { name: user?.userData?.email || '', icon: <Mail />, title: 'Email' },
            { name: capitalize(user?.userData?.user_type || ''), icon: <Person />, title: 'User Type' }
        ])
    }, [user])


    useEffect(() => {
        if(open && md ) setOpen(md)
        else if (open && !md ) setOpen(false)
        else setOpen(false)
    }, [md])

    useEffect(() => {
        setMenulist(getMenu())
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            { processing && <Loader />}
            <Helmet>
                <title>
                    {pageTitle} - School Portal | AAIPSDS
                </title>
            </Helmet>
            <AppBar position="fixed" open={open} md={md}>
                <Toolbar>
                    <Grid container alignItems='center'>
                        <Grid item xs={2} md={6} container alignItems='center'>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleMenu}
                                edge="start"
                                sx={{ mr: 2, ...(open && md && { display: 'none' }) }}
                            >
                                <Menu />
                            </IconButton>
                            {
                                !open && md && 
                                <Typography textAlign='center' my={1}>
                                    AAIPSDS
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={10} md={6} container justifyContent='flex-end' alignItems='center'>
                            <Typography>{user?.userData?.username}</Typography>
                            <IconButton style={{ margin: '0 .5rem' }} title="Account" color="inherit" edge="end" aria-describedby={id} variant="contained" onClick={handleClick}>
                                <AccountCircle />
                            </IconButton>
                            <IconButton title="Logout" color="inherit" edge="end" className='mr-02' onClick={() => setLogoutDialog(true)}>
                                <ExitToApp />
                            </IconButton>
                            
                            <Popover
                                id={id}
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <List>
                                    {
                                        userPopover?.map(({ name, icon, title }, key) => (
                                            <div key={key}>
                                                <ListItem>
                                                    <ListItemIcon style={{ minWidth: 35 }}>{icon}</ListItemIcon>
                                                    <ListItemText className='no-margin'>
                                                        <small>{title}</small>
                                                        <Typography padding={0} fontSize={14} fontWeight='bold'>{name}</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                                { (key < userPopover?.length - 1) && <Divider /> }
                                            </div>
                                        ))
                                    }
                                </List>
                            </Popover>
                            
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>

            <MenuDrawer
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                    },
                    padding: '.5rem',
                    position: 'static'
                }}
                variant={ md ? "permanent" : "temporary" }
                open={open}
                md={md}
            >
                <DrawerHeader>
                    <Typography style={{ flex: 1, marginRight: '-40px', color: PRIMARY_COLOR }} textAlign='center' variant='h6'
                    >
                        <Link to='/'><b>AAIPSDS</b></Link>
                    </Typography>
                    <IconButton onClick={toggleMenu}>
                        <Menu />
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <img src={`${process.env.PUBLIC_URL}/assets/images/aaipsds-logo.png`} width={!open ? 50 : 70} height={!open ? 50 : 70} style={{ margin: '1.5rem auto 0', display: 'block' }} />
                {
                    open && 
                    <Typography fontWeight='bold' textAlign='center' my={1}>
                       {capitalize(user?.userData?.user_type || '')} Portal
                    </Typography>
                }

                
                <List>
                    {
                        menuList.map((link, key) => (
                            <div key={key} style={{ padding: open ? '0 1rem' : 0 }} className='menu'>
                                <MenuAccordion link={link} open={open} active={getActiveLink(link.path, link?.check)} />
                                { !Boolean(key === menuList.length - 1) && <Divider /> }
                            </div>
                        ))
                    }
                </List>
            </MenuDrawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: LIGHT_GREY, minHeight: '100vh' }}>
                <DrawerHeader />
                <Grid container alignItems='center'>
                    <Grid item xs={12} md={8}>
                        <Typography fontSize={30} fontWeight='bold'>{pageTitle}</Typography>
                        <Typography fontSize={15}>
                            {
                                pageTitle &&
                                <>
                                    <Link to='/'>Home</Link> - {prevTitle?.label && <Link to={prevTitle?.link || '#'}>{prevTitle?.label + ' - '}</Link>} {pageTitle}
                                </>
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} container justifyContent='flex-end'>
                        {buttonComponent}
                    </Grid>
                </Grid>
                <Box my={pageTitle && 5}>
                    {children}
                </Box>
            </Box>

            <Dialog open={logoutDialog} onClose={() => setLogoutDialog(false)} maxWidth='md'>
                <DialogContent className='pl-2 pr-2 pt-2 pb-1' style={{ width: 400 }}>
                    <Typography variant='h5' fontWeight='bold'>Confirm Logout</Typography>
                    <Typography>Are you sure you want to logout?</Typography>
                    <DialogActions className='mt-1'>
                        <Button variant='contained' color='secondary' onClick={() => setLogoutDialog(false)}>Cancel</Button>
                        <Button variant='contained' color='primary' onClick={async () => await signOutUser()}>Confirm</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
