import React, { useState } from 'react'
import { Button, CircularProgress, Grid, Container, Card, Dialog, DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import { Link, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import * as Yup from 'yup';
import Nav from '../../components/Nav'
import { Person, PersonOutline } from '@mui/icons-material'
import { registerApplicant } from '../../redux/api/applicant'
import { getCountries, getGenders } from '../../redux/api/static'
import { useSnackbar } from 'notistack'
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { SUCCESS_COLOR } from '../../styles/colors'

const ApplicantRegister = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const [ showSuccessAlert, setShowSuccessAlert ] = useState(null)
    const [ checkTerms, setCheckTerms ] = useState(false);
    const defaultVal = {
        email: '', password: '', 
        title: '',
        confirmEmail: '', confirmPassword: '',
        first_name: '', surname: '', other_names: '', gender: '',
        nationality: '', country_of_birth: '',

        address: '', 
        phone: '',


        referral: '', englishProficiency: ''
    };

    const registerApp = async (values, { setErrors }) => {
        setShowSuccessAlert(false)
        try {
            await registerApplicant(values);
            setShowSuccessAlert(values?.email || '')
        }
        catch (err) {
            setErrors(err?.errors || {})
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    return (
        <>
            <Grid item xs={12} container flexDirection={'column'} style={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Nav />
                </Grid>
                <Grid flex={1} container flexDirection={'column'} alignItems='center' style={{ width: '90%', margin: '7rem auto 0', padding: '0 0 3rem' }}>
                    <Container>
                        <h1 className='mb-0 mt-05'>New Application</h1>
                        <p className='mt-05 mb-2'>Fill the form below to begin your application process</p>

                        <Card className='p-2 pt-3 pb-3'>
                            <Formik 
                                initialValues={defaultVal}
                                validationSchema={Yup.object().shape({
                                    first_name: Yup.string().required('First name is required.'),
                                    phone: Yup.string().required('Phone number is required.'),
                                    gender: Yup.string().required('Gender is required.'),
                                    surname: Yup.string().required('Surname is required.'),
                                    dob: Yup.string().required('Date of birth is required.'),
                                    password: Yup.string().min(8, 'Password field must be more than 8 characters').matches(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                    ).required('Password is required.'),
                                    confirmPassword: Yup.string().required('Password confirmation is required.').oneOf([Yup.ref('password'), null], 'Password does not match'),
                                    email: Yup.string().email('Value must be a valid email address').required('Email Address is required.'),
                                    confirmEmail: Yup.string().required('Email confirmation is required.').oneOf([Yup.ref('email'), null], 'Email Address does not match'),
                                })}
                                onSubmit={registerApp}

                            >
                                {
                                    ({ isSubmitting, values, errors }) => (
                                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <Grid container spacing={3}>
                                                <>
                                                    <Grid item xs={12}>
                                                        <h2 className='mb-0 mt-0'>Account Information</h2>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="surname" label="Surname" required placeholder="Enter surname" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="first_name" label="First Name" required placeholder="Enter first name" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="other_names" label="Other Name(s)" placeholder="Enter other names" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput errors={errors} id="gender" label="Gender" required placeholder="Select gender" 
                                                            selectFunction={getGenders} field='code' fieldDisplay='desc'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput  errors={errors} id="dob" label="Date of Birth" required type="date"/>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput  errors={errors} id="phone" label="Phone Number" required placeholder="Enter phone number" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput errors={errors} id="nationality" label="Nationality" field="code" fieldDisplay='desc' selectFunction={getCountries}  placeholder="Select a country" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput errors={errors} id="country_of_birth" label="Country of Birth" field="code" fieldDisplay='desc' selectFunction={getCountries} placeholder="Select a country" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="email" autoComplete='new-password' required label="Email Address" placeholder="Enter email address" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="confirmEmail" autoComplete='new-password' required label="Confirm Email Address" placeholder="Confirm email address" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput  errors={errors} id="password" autoComplete='new-password' required label="Password" type="password" placeholder="Enter password" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id="confirmPassword" autoComplete='new-password' required label="Confirm Password" type="password" placeholder="Confirm password" />
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput errors={errors} id="english_proficiency" label="English Proficiency" options={[]} placeholder="Select an option" />
                                                    </Grid> */}
                                                    {/* <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput id="referral" label="Referral" options={[]} placeholder="Select an option" />
                                                    </Grid> */}
                                                    <Grid item xs={12}>
                                                        <TextInput errors={errors} id="address" required label="Address" multiline minRows={2} placeholder="Enter address" />
                                                    </Grid>
                                                </>

                                                <Grid item xs={12} mb={1} className='links mt-1 mb-0 pt-0 text-left'>
                                                    <FormControlLabel onChange={({ target }) => setCheckTerms(target.checked) } control={<Checkbox />} label={<p className='f-14'>I agree with the <a target={"_blank"} className='text-primary' href="/assets/doc/AAIPSDS_Short-Term_Training_Course_Terms_and_Conditions.pdf">Terms and Conditions</a></p>} />
                                                </Grid> 

                                                {/* EMERGENCY CONTACT INFORMATION
                                                <>
                                                    <Grid item xs={12}>
                                                        <h2 className='mb-0 mt-1 text-center'>Contact Information</h2>
                                                        <p className='text-center mt-0 mb-0'>Kindly fill in an emergency contact</p>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id="emergencyName" label="Emergency Full Name" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id="emergencyPhone" label="Emergency Phone Number" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id="emergencyEmail" label="Emergency Email" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <SelectInput id="emergencyRelationship" label="Emergency Relationship" placeholder="Select a relationship" options={[]} />
                                                    </Grid>
                                                    <Grid item xs={12} lg={8}>
                                                        <TextInput id="emergencyAddress" multiline label="Emergency Address" />
                                                    </Grid>
                                                </> */}
                                                <Grid item xs={12} md={6} lg={4} className="text-center">
                                                    <Button variant='contained' type='submit' color="primary" fullWidth disabled={isSubmitting || !checkTerms}>
                                                        { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Register' }
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )
                                } 
                            </Formik>
                        </Card>
                    </Container>
                </Grid>
            </Grid>

            <Dialog open={Boolean(showSuccessAlert)} maxWidth='lg'>
                <DialogContent className="p-3 pb-2">
                    <Grid container justifyContent={'center'} alignItems='center' flexDirection={'column'}>
                        <AiFillCheckCircle color={SUCCESS_COLOR} size={100} />
                        <h3 className='mb-05 text-primary'>Applicant Account Created</h3>
                        <p className='mt-05 mb-05'>An email has been sent to your mailbox: <span className='text-primary'>{showSuccessAlert}</span></p>
                        <p className='mt-0'>
                            Verify your email address and login to continue application process
                        </p>
                    </Grid>
                    <DialogActions className='m-0 p-0'>
                        <Button onClick={() => {setShowSuccessAlert(null); history.push('/login')}} variant='contained' color='primary'>Close</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ApplicantRegister