import { Cancel, Close, InfoOutlined, Lock, Upload, UploadOutlined, Visibility } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Field, ErrorMessage } from 'formik';
import { useRef, useState } from "react";
// import { streamDocument } from "../../redux/api/utilities";
import { openFileInNewBrowser } from "../../util";
import { Document, Page, pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
 // pdfjsLib.GlobalWorkerOptions.workerSrc = '../node_modules/pdfjs-dist/build/pdf.worker.js';
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentInput = ({ customChange, preventFormikSetValue = false, readOnly, ...props}) => {
    const inputRef = useRef(null)
    const [ uploadAlert, showUploadAlert ] = useState(null)
    const [ file, setFile ] = useState(null)

    const [ viewer, showViewer ] = useState("")
    const [numPages, setNumPages] = useState(null);
    const [loading, setLoading] = useState(false);

    // setOptions({ workerSrc: '/pdf.worker.min.js', })

    const openDocument = async (value) => {
        try {
            setLoading(true)
            // const res = await streamDocument(value);
            showViewer(value)
        }
        catch(err) {

        }
        finally {
            setLoading(false);
        }
    }

    const handleChange = ({ target }, field, form) => {
        const file  = target?.files[0]
        if(file) {
            if(file.size/1024 < 10240) {
                if(file.type === 'image/jpeg' || file.type === 'application/pdf' || file.type === 'image/png') {
                    setFile(file)
                    if(!preventFormikSetValue) form.setFieldValue(field.name, file);
                    if(customChange) customChange(field.name, file)
                } else {
                    showUploadAlert("Invalid file format, only JPEG, PNG and PDF files are allowed")
                }
            } else {
                showUploadAlert("File size is must be less than 10MB")
            }
        } else {
            setFile(null)
            form.setFieldValue(field.name, '');
        }
    }

        
    const closeDialog = () => {
        showViewer(null)
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

	return (
        <div>
            <label className="label">{props.label} {props.required && '*'} { readOnly && <Lock fontSize="10px" style={{ marginBottom: '-2px' }} />} {props.tip && <Tooltip title={props.tip} ><InfoOutlined fontSize="10px" style={{ marginBottom: '-2px' }} /></Tooltip>}</label>
            <Field id={props.id} name={props.id} label={props.label}>
                {({ field, form }) => (
                    <div>
                        <input ref={inputRef} hidden type='file' accept={props.accept} onChange={(e) => handleChange(e, field, form)} />
                        {/* {JSON.stringify(field)} */}
                        <TextField 
                            { ...props }
                            id={props.id}
                            name={props.id}
                            label={null}
                            disabled={props.disabled}
                            type={props.type || 'text'}
                            required={props.required}
                            multiline={props.multiline} 
                            minRows={props.minRows}
                            variant="outlined"
                            fullWidth
                            value={file?.name || field.value || props?.value || ''}
                            placeholder={props?.placeholder || `Click to upload ${props.label}`}
                            error={Boolean(form.errors[props.id] && form.touched[props.id])}
                            inputProps={{
                                style: { padding: 0 }
                            }}
                            className="document-input"
                            InputProps={{
                                onKeyDown: () => { return false },
                                startAdornment: (
                                    !readOnly &&
                                    <InputAdornment position="start">
                                        <Button
                                            variant="outlined" title="Upload Document"
                                            startIcon={<Upload />}
                                            style={{ borderTopRightRadius: 0 }}
                                            onClick={() => inputRef.current.click()}
                                        >
                                            Upload
                                        </Button>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            disabled={!Boolean(file) && !Boolean(props?.value || field?.value)}
                                            variant="contained" color="secondary" title="View document"
                                            onClick={() => {
                                                if(file) openFileInNewBrowser(file)
                                                else if(props?.value || field?.value) openDocument(props?.value || field?.value)
                                            }}
                                        >
                                            <Visibility />
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {   
                            Boolean(form.errors[props.id] && form.touched[props.id]) &&
                            <small className="error input-feedback">{form.errors[props.id]}</small>
                        }
                        {/* <ErrorMessage className="error input-feedback" component="div" name={props.id} /> */}
                    </div>
                )}
            </Field>


            <Dialog open={Boolean(uploadAlert)}>
                <DialogContent className="p-2 pb-1">
                    <div className="d-flex justify-content-center flex-direction-column align-items-center">
                        <Cancel color="error" style={{ fontSize: '70px' }} />
                        <h5 className="mt-05 mb-0">File Upload Error</h5>
                        <p className="mt-05">{uploadAlert}</p>
                    </div>
                </DialogContent>
                <DialogActions className="p-1 pt-0">
                    <Button color="secondary" variant="contained" onClick={() => showUploadAlert(null)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={Boolean(viewer)} maxWidth="lg">
                <DialogTitle>
                    <span className="m-0">{props.label}</span>
                </DialogTitle>
                {
                    viewer &&
                    <DialogContent className="p-2 pb-1">
                        {
                            // String(viewer).startsWith('data:application/pdf;base64,') ?
                            <Document file={viewer} onLoadSuccess={onDocumentLoadSuccess} 
                                onLoadError={(err) => console.log(err)} 
                                onSourceError={((err) => console.log(err))}
                                error="Failed to load file"
                            >
                                {
                                    [...new Array(numPages)].fill('0').map((val, index) => (
                                        <Page pageNumber={index + 1} key={index} />
                                    ))
                                }
                            </Document>
                            // :
                            // <img src={viewer} style={{ display: 'block', maxWidth: '100%', margin: 'auto' }} />
                        }
                    </DialogContent>
                }
                <DialogActions className="p-1">
                    <Button color="secondary" variant="contained" onClick={() => showViewer(null)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
	);
};

export default DocumentInput;