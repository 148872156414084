import { InfoOutlined, Lock } from "@mui/icons-material";
import { Autocomplete, capitalize, TextField, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Field, ErrorMessage } from 'formik';
import { useState } from "react";
import { useAsync } from 'react-async';

const SelectInput = ({ fieldDisplay='name', filterField = '', preventFormikSetValue = false, customChange, filterValue = 'code', readOnly, selectFunction, ...props}) => {
    const [open, setOpen] = useState(false);
    const { data, error, isLoading } = useAsync({ promiseFn: selectFunction });

    let options = data || props?.options || []
    if(filterField) options = options.filter((op) => op[filterField] === filterValue)


	// if (isLoading) return 'Loading...';
	// if (error) return `Something went wrong: ${error.message}`;

	return (
        <Field id={props.id} name={props.id} label={props.label}>
            {({ field, form }) => (
                <div>
                    <label className="label">{props.label} {props.required && '*'} { readOnly && <Lock fontSize="10px" style={{ marginBottom: '-2px' }} />} {props.tip && <Tooltip title={props.tip} ><InfoOutlined fontSize="10px" style={{ marginBottom: '2px' }} /></Tooltip>}</label>
                    <Autocomplete
                        id={props.id}
                        name={props.id}
                        label={null}
                        options={options.sort((a, b) => (a[fieldDisplay] > b[fieldDisplay]) ? 1 : -1)}
                        getOptionLabel={option => capitalize(option[fieldDisplay] || '')}
                        value={options.filter(lval => lval[props.field] === (props?.value || field.value))[0] || null}
                        onChange={(e, value) => {
                            if(!preventFormikSetValue) form.setFieldValue(field.name, value?.[props.field] || '', false);
                            if(customChange) customChange(field.name, value?.[props.field] || '')
                        }}
                        onBlur={() => {
                            form.setTouched({ ...form.touched, [field.name]: true });
                        }}
                        open={open}
                        onOpen={() => !readOnly && setOpen(true)}
                        onClose={() => setOpen(false)}
                        disableClearable={readOnly}
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                name={props.id}       
                                placeholder={props?.placeholder || `Select ${props.label}`}  
                                label={null}
                                required={props.required}
                                variant="outlined" 
                                error={Boolean(form.errors[props.id] && form.touched[field.name])}
                                InputLabelProps={{
                                    // className: classes.labelStyle
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    ...props
                                }}
                            />
                        )}
                        required={props.required}
                        // className={classes.root}
                        variant="outlined"
                        
                    />
                    {   
                        Boolean(form.errors[props.id] && form.touched[field.name]) &&
                        <small className="error input-feedback">{form.errors[props.id]}</small>
                    }
                    {/* <ErrorMessage className="error input-feedback" component="div" name={props.id} /> */}
                </div>
            )}
        </Field>
	);
};

export default SelectInput; 