import { InfoOutlined, Lock } from "@mui/icons-material";
import { capitalize, TextField, Tooltip } from "@mui/material";
import { Field, ErrorMessage } from 'formik';


const TextInput = ({ customChange, valueFirst = false, preventFormikSetValue = false, capitalizeValue = false, ...props }) => {
	return (
        <div>
            <label className="label">{props.label} {props.required && '*'} { props.readOnly && <Lock fontSize="10px" style={{ marginBottom: '-2px' }} />} {props.tip && <Tooltip title={props.tip} ><InfoOutlined fontSize="10px" style={{ marginBottom: '2px' }} /></Tooltip>}</label>
            <Field id={props.id} name={props.id} label={props.label}>
                {({ field, form }) => (
                    <div>
                        <TextField 
                            { ...props }
                            id={props.id}
                            name={props.id}
                            label={null}
                            disabled={props.disabled}
                            type={props.type || 'text'}
                            required={props.required} 
                            placeholder={props?.placeholder || `Enter ${props.label}`}
                            InputProps={{ readOnly: props.readOnly, ...props.InputProps, autoComplete: "new-password" }}
                            inputProps={{
                                ...props.inputProps, autoComplete: "new-password"
                            }}
                            multiline={props.multiline} minRows={props.minRows}
                            variant="outlined"
                            fullWidth autoComplete="new-password"
                            error={Boolean(form.errors[props.id] && form.touched[field.name])}
                            value={valueFirst ? props?.value || '' : capitalizeValue ? capitalize(field?.value || props?.value || '') : field?.value || props?.value || ''}
                            onChange={({ target }) => {
                                const value = target.value || ''
                                if(!preventFormikSetValue) form.setFieldValue(field.name, value, false);
                                if(customChange) customChange(field.name, value)
                            }}
                            onBlur={() => {
                                form.setTouched({ ...form.touched, [field.name]: true });
                            }}
                        />
                        {   
                            Boolean(form.errors[props.id] && form.touched[field.name]) &&
                            <small className="error input-feedback">{form.errors[props.id]}</small>
                        }
                        {/* <ErrorMessage className="error input-feedback" component="div" name={props.id} /> */}
                    </div>
                )}
            </Field>
        </div>
	);
};

export default TextInput;