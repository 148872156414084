import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/DashboardContainer';
import { useSnackbar } from 'notistack'
import { getApplications } from '../../redux/api/applicant';
import { Button, Grid, Box, IconButton, Chip, capitalize, Divider } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import appRoutes from '../../routes/appRoutes'
import { DataGrid } from '@mui/x-data-grid';
import { Edit, ListAlt, VisibilityOutlined } from '@mui/icons-material';
import { getColor } from '../../util';
import moment from 'moment'
import { getAnalytics } from '../../redux/api/internal';
import DashLet from '../../components/common/Dashlet';
import { BLACK_COLOR, ERROR_COLOR, GREEN_COLOR, GREY_COLOR, SECONDARY_COLOR, SUCCESS_COLOR, TERTIARY_COLOR, WARNING_COLOR } from '../../styles/colors';

const darkShade = { fill: 'rgba(0, 0, 0, 0.2)', borderColor: 'rgba(0, 0, 0, 0.2)' }

const Dashboard = () => {
    const [ processing, setProcessing ] = useState(false)
    const [ analytics, setAnalytics ] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const init = async () => {
        try {
            setProcessing(true)
            const res = await getAnalytics()
            setAnalytics(res)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <DashboardContainer pageTitle='Staff Dashboard' processing={processing}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"All Applications"} data={analytics?.applications?.all || 0} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Initiated Applications"} data={analytics?.applications?.initiated || 0} color={SECONDARY_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Submitted Applications"} data={analytics?.applications?.submitted || 0} color={TERTIARY_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Granted Applications"} data={analytics?.applications?.granted || 0} color={SUCCESS_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Accepted Applications"} data={analytics?.applications?.accepted || 0} color={GREEN_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Rejected Applications"} data={analytics?.applications?.rejected || 0} color={'brown'} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"Declined Applications"} data={analytics?.applications?.declined || 0} color={WARNING_COLOR} />
                </Grid>
            </Grid>

            <Divider className="mt-2 mb-2" />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"All Staff"} data={analytics?.staff || 0} color={GREY_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"All Students"} data={analytics?.students || 0} color={SUCCESS_COLOR}  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt style={darkShade} />} label={"All Programmes"} data={analytics?.programmes || 0} color={'lightgrey'}  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DashLet icon={<ListAlt />} label={"All Program Categories"} data={analytics?.programmeCategories || 0} color={TERTIARY_COLOR}  />
                </Grid>
            </Grid>
        </DashboardContainer>
    )
}

export default Dashboard