import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch, useHistory, withRouter, Redirect, Link } from 'react-router-dom'
import AOS from 'aos';
import { ArrowUpward } from '@mui/icons-material';
import $ from 'jquery'
import { LoginPage, Page404, VerificationPage } from '../pages';
import { signOutUser } from '../redux/actions/userActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicantRegisterPage } from '../pages/applicant';
import applicantsRoutes from './applicantsRoutes'
import applicationRoutes from './applicationRoutes'
import InternalRoutes from './internalRoutes';
import StudentRoutes from './studentRoutes';

const ScrollToTop = () => {
    const history = useHistory();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [history.location.pathname]);
  
    return null;
};

const ResetScroll = withRouter(ScrollToTop);

const Routes = ({ user }) => {
    const [ authenticated, setAuthenticated ] =  useState(user?.authenticated || false);

    const scrollToTop = () => {
        $("html, body").animate({ scrollTop: 0 }, 1000);
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const scrollToTop = document?.querySelector('#scrollToTop') || null
            const scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollToTop) {
                (scroll > 100) ? scrollToTop?.setAttribute('style', "display: flex") : scrollToTop?.removeAttribute('style')
            }
        })
    }, [])

    useEffect(() => {
        setAuthenticated(user?.authenticated)
    }, [user])

    const PrivateRoute = ({ component: Component, exact, ...rest }) => {
        return (
          <Route
            {...rest}
            render={(props) =>
              authenticated ? <Component {...props} /> : <Redirect to={`/login`} />
            }
            exact={exact}
          />
        );
    };

    return (
        <BrowserRouter>
            <ResetScroll />
            <Switch>
                <Route exact path="/">
                    <Redirect to={`/login`} />
                </Route>

                <Route exact path="/staff">
                    <Redirect to={`/internal`} />
                </Route>

                <Route exact path="/admin">
                    <Redirect to={`/internal`} />
                </Route>
                
                <Route component={LoginPage} exact path="/login" />

                <Route component={ApplicantRegisterPage} exact path="/applicant/new" />

                <Route component={VerificationPage} exact path="/verification/:email" />

                <PrivateRoute component={applicantsRoutes} path={`/applicant`} />

                <PrivateRoute component={InternalRoutes} path={`/internal`} />

                <PrivateRoute component={StudentRoutes} path={`/student`} />

                <PrivateRoute component={applicationRoutes} path={`/application`} />

                <Route path="**">
                    <Page404 />
                </Route>
            </Switch>
            
            <Link to="#" className="scroll-to-top" id="scrollToTop" onClick={() => scrollToTop()}>
                <ArrowUpward />
            </Link>
        </BrowserRouter>
    )
}

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);