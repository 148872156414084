import { $axios } from "./config";
const url = '/auth';

export const loginUser = async (form) => {
    return $axios.post('/auth/login', form);
}

export const verifyEmail = async (form) => {
    return $axios.post(`${url}/email_verification`, form);
}

export const sendVerifyEmail = async (email) => {
    return $axios.get(`${url}/send_verification/${email}`,);
}