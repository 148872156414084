import { $axios } from "./config";
import firebaseApp from './firebaseConfig'
import { getStorage, ref, uploadString, getDownloadURL, uploadBytes } from "firebase/storage"; 
const url = '/applicant';

export const registerApplicant = async (form) => {
    return $axios.post(`${url}/register`, form);
}

export const loginApplicant = async (form) => {
    return $axios.post(`${url}/login`, form);
}

export const getApplicantData = async (user_id) => {
    return $axios.get(`${url}/${user_id}`);
}

export const getApplicationDetails = async (app_id) => {
    return $axios.get(`${url}/applications?id=${app_id}`);
}

export const getApplicationPaymentDetails = async (app_id) => {
    return $axios.get(`${url}/get_application_payment_details/${app_id}`);
}

export const updatePersonalDetails = async (payload) => {
    return $axios.post(`${url}/update_personal_details`, payload);
}

export const getApplications = async (id) => {
    return $axios.get(`${url}/applications${id ? '?id='+id : ''}`);
}

export const updateEducationDetails = async (payload) => {
    return $axios.post(`${url}/update_eduction_details`, payload);
}

export const updateDocuments = async (payload) => {
    return $axios.post(`${url}/update_documents`, payload);
}

export const updateProgrammeDetails = async (payload) => {
    return $axios.post(`${url}/update_programme_details`, payload);
}

export const updatePaymentDetails = async (payload) => {
    return $axios.post(`${url}/update_payment_details`, payload);
}

export const confirmApplicantPayments = async (payload) => {
    return $axios.post(`${url}/confirm_applicant_payments`, payload);
}

export const updatePassportFireStore = async (payload) => {
    const storage = getStorage();
    const {     
        passport, user_id
    } = payload;

    const storageRef = ref(storage, `applicants/${user_id}/passport.png`);

    await uploadString(storageRef, passport, 'data_url')

    const url = await getDownloadURL(storageRef)

    return url;
}

export const updateDocumentFireStore = async (payload) => {
    const storage = getStorage();
    const {     
        id, file, user_id
    } = payload;

    const storageRef = ref(storage, `applicants/${user_id}/${id}`);

    await uploadBytes(storageRef, file, { contentType: file.contentType })

    const url = await getDownloadURL(storageRef)

    return url;
}

export const updateAdmissionStatus = async (payload) => {
    return $axios.post(`${url}/update_admission_status`, payload);
}

export const updatePassport = async (payload) => {
    return $axios.post(`${url}/update_applicant_passport`, payload);
}

export const completeDetails = async (app_id) => {
    return $axios.get(`${url}/complete_application/${app_id}`);
}


export const confirmPayment = async (rrr, orderId) => {
    return $axios.get(`${url}/check_app_payment_status?rrr=${rrr || ''}&orderId=${orderId || ''}`);
}