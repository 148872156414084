import { $axios } from "./config";

const url = '/system';

const getCities = async () => {
    return $axios.get(`${url}/cities`);
}

const getStates = async () => {
    return $axios.get(`${url}/states`);
}

const getLGAs = async () => {
    return $axios.get(`${url}/lgas`);
}

const getCountries = async () => {
    return $axios.get(`${url}/countries`);
}

const getRelationships = async () => {
    return $axios.get(`${url}/relationships`);
}

const getGenders = async () => {
    return $axios.get(`${url}/genders`);
}

const getQualifications = async () => {
    return $axios.get(`${url}/qualifications`);
}

const getProgrammes = async () => {
    return $axios.get(`${url}/programmes`);
}

const getProgrammesByCategoryId = async (categoryId) => {
    return $axios.get(`${url}/programmes/${categoryId}`);
}

const getProgrammesCategories = async () => {
    return $axios.get(`${url}/programme_categories`);
}

const getApplicationStatuses = async () => {
    return $axios.get(`${url}/application_statuses`);
}

export {
    getCities, getCountries, getLGAs, getStates, getGenders, getApplicationStatuses,
    getRelationships, getQualifications, getProgrammes, getProgrammesByCategoryId, getProgrammesCategories
}