const appRoutes = {
    applicant: {
        dashboard: '/applicant/dashboard',
        applications: '/applicant/applications',
        new: '/applicant/applications/new',
    },
    application: {
        educationalInformation: '/application/educational-information',
        programmeInformation: '/application/programme-information',
        payment: '/application/payment',
        review: '/application/review',

    },
    staff: {
        dashboard: '/internal/dashboard',
        applications: '/internal/applications',
        resources: {
            root: '/internal/website-resources',
            news: '/internal/website-resources/news',
            events: '/internal/website-resources/events',
            blog: '/internal/website-resources/blog',
            gallery: '/internal/website-resources/gallery',
        }
    },
    student: {
        dashboard: '/student/dashboard',
        profile: '/student/profile',
        courses: '/student/courses',
        fees: '/student/fees',
        results: '/student/results',
    },
    internal: {
        users: '/internal/users',
        createUser: '/internal/users/user',
        rolesPrivileges: '/internal/users/roles_privileges',
    }
}

export default appRoutes