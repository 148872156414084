import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { ApplicantEducationalDetailsPage, NewApplicationPage, ApplicantProgrammeDetailsPage, PaymentPage, ApplicantDashboardPage } from '../pages/applicant';
import Review from "../pages/applicant/Review";
import Page404 from "../pages/Page404";

const ApplicantsRoutes = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${path}/new`} />
            </Route>

    
            <Route exact path={`${path}/new`} component={NewApplicationPage} />
            
            <Route exact path={`${path}/:id`} component={NewApplicationPage} />
            
            <Route exact path={`${path}/educational-information`} component={ApplicantEducationalDetailsPage} />

            <Route exact path={`${path}/:id/programme-information`} component={ApplicantProgrammeDetailsPage} />

            <Route exact path={`${path}/programme-information`} component={ApplicantProgrammeDetailsPage} />

            <Route exact path={`${path}/:id/payment`} component={PaymentPage} />

            <Route exact path={`${path}/:id/review`} component={Review} />

            <Route path="**">
                <Page404 />
            </Route>
        </Switch>
    )
}

export default ApplicantsRoutes
