import store from './redux/store';
import Routes from './routes'
import Theme from './styles/theme';
import "aos/dist/aos.css";
import { Provider } from "react-redux";
import { SnackbarProvider } from 'notistack';
import { useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


const App = () => {
    const stackRef = useRef(null);

    const onClose = (key) => () => {
        stackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            ref={stackRef}
            dense
            maxSnack={1}
            preventDuplicate
            autoHideDuration={3000}
            variant="success"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            action={(key) => (
                <IconButton size="small" color='inherit' onClick={onClose(key)} sx={{ p: 0.5 }}>
                    <Close />
                </IconButton>
            )}
        
        >
            <Provider store={store}>
                <Theme>
                    <Routes />
                </Theme>
            </Provider>
        </SnackbarProvider>
    );
}

export default App;
