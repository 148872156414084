import { ExpandMore } from '@mui/icons-material'
import { Grid, Button, CircularProgress, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import SelectInput from './common/SelectInput'
import TextInput from './common/TextInput'

const Filters = ({ formFields = [], searchFunction }) => {
    let fields = {};

    useEffect(() => {
        formFields.forEach(({ id }) => {
            fields[id] = '';
        })
    }, [formFields])
    
    return (
        <Accordion style={{ boxShadow: 'none', borderRadius: 7, border: '1px solid grey' }}>
            <AccordionSummary expandIcon={<ExpandMore />}><h3 className='no-margin f-bold ml-1 mr-1'>Filters:</h3> </AccordionSummary>
            <AccordionDetails className='no-margin ml-1 mr-1'>
                <Formik
                    initialValues={{ ...fields }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        try {
                            setSubmitting(true)
                            let newValues = {}
                            Object.keys(values).forEach((val) => {
                                newValues[val] = values[val] || ''
                            })
                            await searchFunction(newValues)
                        }
                        catch (err) {
                            console.log(err)
                        }
                        finally {
                            setSubmitting(false)
                            // resetForm(formFields)
                        }
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <Grid container spacing={3} paddingBottom={3} alignItems='flex-end'>
                                {
                                    formFields?.map(({ id = '', label = '', type = 'text', show = true, filterValue = '', ...form }, key) => (
                                            
                                        show && <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                                            {
                                                type === 'select' ?
                                                <SelectInput id={id} label={label} {...form} />
                                                :
                                                <TextInput id={id} label={label} type={type} {...form} />
                                            }
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Button variant='contained' type='submit' disabled={isSubmitting} fullWidth>
                                        { 
                                            isSubmitting?
                                            <CircularProgress size={15} color='inherit' />
                                            :
                                            'Filter'
                                        }</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </AccordionDetails>
        </Accordion>
    )
}
export default Filters