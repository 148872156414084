import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { ApplicantEducationalDetailsPage, ApplicantPersonalDetailsPage, ApplicantProgrammeDetailsPage, PaymentPage, ApplicantDashboardPage, NewApplicationPage } from '../pages/applicant';
import Page404 from "../pages/Page404";

const ApplicantsRoutes = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${path}/dashboard`} />
            </Route>

            <Route exact path={`${path}/application`}>
                <Redirect to={`${path}/application/all`} />
            </Route>

            <Route exact path={`${path}/dashboard`} component={ApplicantDashboardPage} />

            <Route exact path={`${path}/applications/new`} component={NewApplicationPage} />

            <Route exact path={`${path}/applications/:id`} component={NewApplicationPage} />
            
            <Route exact path={`${path}/applications`} component={ApplicantDashboardPage} />

            {/* <Route exact path={`${path}/application/:app_id/personal-information`} component={ApplicantPersonalDetailsPage} /> */}

            <Route exact path={`${path}/application/:app_id/educational-information`} component={ApplicantEducationalDetailsPage} />

            <Route exact path={`${path}/application/:app_id/programme-information`} component={ApplicantProgrammeDetailsPage} />

            <Route exact path={`${path}/application/:app_id/payment`} component={PaymentPage} />

            <Route path="**">
                <Page404 />
            </Route>
        </Switch>
    )
}

export default ApplicantsRoutes
