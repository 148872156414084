import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import * as Yup from 'yup';
import { getProgrammes, getProgrammesCategories } from '../../redux/api/static'
import DocumentInput from '../../components/common/DocumentInput'
import { updateDocumentFireStore } from '../../redux/api/applicant'

export const documents = [
    { label: 'Curriculum Vitae (CV)', id: 'cv', optional: false },
    // { label: 'Means of Identification', id: 'identification', optional: false },
    // { label: "O'level Result", id: 'o_level_result', optional: false },

    // { label: 'Birth Certificate', id: 'birth_certificate', optional: false },
    // { label: 'Work ID (Front & Back Copy)', id: 'work_id', optional: true },
    // { label: '1st Degree/PGD Certificate', id: 'degree_pgd_certificate', optional: true },
    // { label: 'NYSC (Discharge Certificate/Call-up Letter)', id: 'nysc', optional: true },
    // { label: 'University Transcript', id: 'transcript', optional: true },
]

const DocumentUpload = ({ updateApplication, applicationDetails, nextStep, readOnly }) => {
    const [ programmes, setProgrammes ] = useState([]);

    const init = async () => {
        try {
            const progs = await getProgrammes();
            setProgrammes(progs)
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = async (values) => {
        try {
            // const data = {}
            const data = await new Promise((resolve) => {
                let index = -1;
                let obj = {};
                documents.forEach(async (doc, key) => {
                    const url = (typeof(values[doc.id]) == 'object') ? await updateDocumentFireStore({ id: doc.id, file: values[doc.id], user_id: values.user_id }) : values[doc.id];
                    obj[doc.id] = url;
                    index++;
                    if(index === (documents.length - 1)) resolve(obj)
                })
            })
            await updateApplication({ ...applicationDetails, ...data }, 'document')
        }
        catch(err) {

        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <Card className='p-2 pt-3 pb-3'>
            <h2 className="text-center">Document Upload</h2>
            {/* <p className="text-center">No document to upload. Click save and proceed</p> */}
            <p className="text-center">Kindly note that fields with * are mandatory</p>
            <Formik 
                initialValues={{ ...applicationDetails }} 
                enableReinitialize
                validationSchema={Yup.object().shape({
                    programme_category: Yup.string().required('Programme category is required.'),
                    programme: Yup.string().required('Programme is required.'),
                })}
                onSubmit={handleSubmit}
            >
                {
                    ({ isSubmitting, values, errors, setFieldValue }) => (
                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={2} alignItems='flex-end'>
                                {
                                    documents.map((doc, key) => (
                                        <Grid item xs={12} key={key} md={6}>
                                            <DocumentInput 
                                                required={!doc.optional}
                                                id={doc.id}
                                                label={doc.label}
                                                readOnly={readOnly}
                                            />
                                        </Grid>
                                    ))
                                }
                                <Grid item container xs={12} spacing={3} className="mt-05">
                                    <Grid item xs={12} md={6}>
                                        <Button variant='contained' color={"secondary"} onClick={() => nextStep(2)} fullWidth>
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {
                                            !(readOnly) ?
                                            <Button variant='contained' type='submit' color="primary" fullWidth>
                                                { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Save & Proceed' }
                                            </Button>
                                            :
                                            <Button variant='contained' onClick={() => nextStep(4)} color="primary" fullWidth>
                                                Proceed
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                } 
            </Formik>
        </Card>
    )
}
export default DocumentUpload;