import { Button, Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Loader from '../components/common/Loader'
import { verifyEmail } from '../redux/api/auth'

const Verification = () => {
    const { email } = useParams()
    const [ processing, setProcessing ] = useState(false);
    const [ emailSuccess, setEmailSuccess ] = useState('');

    const init = async () => {
        setProcessing(true)
        try {
            await verifyEmail({ email });
            setEmailSuccess('success')
        }
        catch (err) {
            setEmailSuccess('failed')
        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init()    
    }, [])

    return (
        <Container className='p-3' style={{ minHeight: '100vh' }}>
            {
                processing ?
                <Loader />
                :
                <Grid container justifyContent={'center'} alignItems='center'>
                    <Grid item xs={12} textAlign={'center'}>
                        <Link to='/'>
                            <img className='logo' src='/assets/images/aaipsds-logo.png' alt='aaipsds' style={{ height: '100px', textAlign: 'center' }} />
                        </Link>
                        {
                            emailSuccess == 'success' ?
                            <>
                                <h3 className='mt-0 mb-1'>Email Verification Successful</h3>
                                <p className='mt-05 mb-0'>Your email address has been successfully verified</p>
                                <p className='mt-05 mb-05'>You can now login to continue your application process</p>
                            </>
                            :
                            <>
                                <h3 className='mt-0 mb-1'>Email Verification Failed</h3>
                                <p className='mt-05 mb-05'>We could not verify your email address, kindly try again or contact support</p>
                            </>
                        }
                        <Link to='/login'>
                            <Button>Go Back Home</Button>
                        </Link>
                    </Grid>
                </Grid>
            }
        </Container>
    )
}

export default Verification