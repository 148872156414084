import { Grid, Typography } from '@mui/material';
import React from 'react';
import { PRIMARY_COLOR } from '../../styles/colors';

const DashLet = ({ icon, label, data, color = PRIMARY_COLOR }) => {
    return (
        <Grid container className='dashlet' alignItems={'center'} flexDirection="row-reverse" style={{ background: color }}>
            <div style={{ position: 'absolute' }} className='icon'>
                {icon}
            </div>
            <Grid item className='p-0 pt-1 pb-1 text-left' xs={12}>
                <Typography fontWeight={'bold'} fontSize={25}>{data}</Typography>
                <h3 style={{ fontWeight: 'normal', fontSize: '14px', margin: 0 }}>{label}</h3>
            </Grid>
        </Grid>    
    );
};

export default DashLet;
