import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardContainer from '../../components/DashboardContainer'
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { createOrUpdateUser, getUserDetails, getUsers } from '../../redux/api/userMgt';
import { useSnackbar } from 'notistack';
import SelectInput from '../../components/common/SelectInput';
import TextInput from '../../components/common/TextInput';
import { ApplicantFormData, StaffFormData, StudentFormData, userTypes } from '../../util/userTypeForm';
import appRoutes from '../../routes/appRoutes';

const CreateOrEditUsers = () => {

    const defaultUser = { 
        "user_type": "",
        "role": "",
        "phone": "",
        "email": "",
        "id": ""
    }
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [ selectedUser, setSelectedUser ] = useState(defaultUser);
    const [ processing, setProcessing ] = useState(false);
    const [ showAlert, setShowAlert ] = useState(false);
    const location = useLocation();
    const params = useParams();

    const init = async (id) => {
        try {
            setProcessing(true)
            if(id) {
                const res = await getUserDetails(id);
                setSelectedUser(res)
            } else {
                setSelectedUser(defaultUser)
            }
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'Unable to fetch User Data', { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    const submit = async (values, { resetForm }) => {
        try {
            const res = await createOrUpdateUser(values);
            enqueueSnackbar(res?.message || `Internal User ${values?.id ? 'Updated' : 'Added'} successfully`);

            setShowAlert(true)
            // if(!values?.id) {
            //     setSelectedUser(defaultUser)
            //     resetForm();
            // }
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    useEffect(() => {
        init(params?.id)
    }, [params])

    return (
        <DashboardContainer pageTitle={`${selectedUser?.id ? 'Update' : 'Create'} User`} processing={processing} pageDescription=''>
            <Card className='custom-card'>
                <Box>
                    <Formik initialValues={{ ...selectedUser }} enableReinitialize onSubmit={submit}
                        validationSchema={Yup.object().shape({
                            first_name: Yup.string().required('First name is required.'),
                            // phone: Yup.string().required('Phone number is required.'),
                            surname: Yup.string().required('Surname is required.'),
                            email: Yup.string().email('Value must be a valid email address').required('Email Address is required.'),
                            confirmEmail: Yup.string().oneOf([Yup.ref('email'), null], 'Email Address does not match'),
                        })}
                    >
                        {
                            ({ isSubmitting, values }) => (
                                <Form>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <SelectInput required readOnly={Boolean(values?.id)} options={userTypes} field='code' fieldDisplay="label" id="user_type" label="User Type" />    
                                        </Grid>
                                        { (values?.user_type == 'staff') && <StaffFormData isEdit={Boolean(values?.id)} /> }
                                        { (values?.user_type == 'student') && <StudentFormData /> }
                                        { (values?.user_type == 'applicant') && <ApplicantFormData /> }
                                        
                                        <Grid item xs={12} container justifyContent={'flex-end'}>
                                            <Button variant='contained' type="submit" disabled={isSubmitting}>
                                                { isSubmitting ? <CircularProgress color="inherit" size={15} /> : Boolean(selectedUser?.id) ? 'Edit User' : 'Add User' }
                                            </Button> 
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                    </Formik>
                </Box>
            </Card>

            <Dialog open={showAlert}>
                <DialogTitle>
                    <span className='f-bold'>{`Account ${selectedUser?.id ? 'Update' : 'Creation'} Successful`}</span>
                </DialogTitle>
                <DialogActions className='no-padding pr-1 pb-1'>
                    <Button color='primary' variant='contained' onClick={() => history.push(appRoutes.internal.createUser)}>Create New User</Button>
                    <Button color='secondary' variant='contained' onClick={() => history.push(appRoutes.internal.users)}>View All Users</Button>
                </DialogActions>
            </Dialog>
        </DashboardContainer>
    )
}

export default CreateOrEditUsers