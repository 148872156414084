import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR } from '../../styles/colors'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

const MUIAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
}));
  
const AccordionSummary = styled(({ active, ...props}) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: active ? '#fff' : '#000' }} />}
      {...props}
    />
  ))(({ theme, active, ...props }) => ({
    backgroundColor: active ? PRIMARY_COLOR : 'transparent',
    color: active ? '#fff' : '#000',
    borderRadius: '5px',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 7px 0 5px',
    '& .MuiAccordionSummary-expandIconWrapper': {
      display: props.links == 0 ? 'none' : 'flex'  
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',

    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));
  
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '5px 0 5px 15px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const MenuAccordion = ({ link, active = false, user, open }) => {
    const location = useLocation()
    const history = useHistory()
    const [expanded, setExpanded] = useState(active);
    const [userData, setUserData] = useState(null);
    const { active : menuActive = true  } = link

    const togglePanel = () => () => {
        setExpanded(!expanded);
    };

    const getActiveLink = (link) => {
        const path = String(window.location.href).split(window.location.host)[1]
        return Boolean(path == link.toLowerCase())
    } 

    useEffect(() => {
        setUserData(user?.userData || null)
    }, [user])

    return (
        <div onClick={() => ((menuActive && !open) || (menuActive && !(link?.subMenus?.length > 0))) && history.push(link.path) } title={link.title} style={{ cursor: 'pointer' }}>
            {
                !open ?
                Boolean(userData?.privileges?.includes(link?.id) || link?.id === '') &&
                <div className={ active ? 'menu-close-icons active' : 'menu-close-icons'}>{link.icon}</div>
                :
                Boolean(userData?.privileges?.includes(link?.id) || link?.id === '') &&
                <MUIAccordion expanded={expanded} onChange={togglePanel()}>
                    <AccordionSummary active={active} links={link?.subMenus?.length || 0} style={{ borderRadius: 0 }}>
                        {link.icon} 
                        <Typography fontSize={14}>{link.title}</Typography>
                    </AccordionSummary>
                    {
                        link?.subMenus?.length > 0 &&
                        <AccordionDetails>
                            {
                                link.subMenus.map((lnk, key) => (
                                    Boolean(userData?.privileges?.includes(lnk?.id) || link?.id === '') &&
                                    <Link to={menuActive ? `${lnk.path}` : '#'} key={key}>
                                        <ListItem button key={key} style={{ padding: '5px' }} className={getActiveLink(lnk.path) ? 'link-active' : ''}>
                                            <ListItemIcon style={{ minWidth: 'auto', marginRight: '.5rem' }}>
                                                {lnk.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={lnk.title} primaryTypographyProps={{ fontSize: 14 }} />
                                        </ListItem>                                
                                    </Link>
                                ))
                            }
                        </AccordionDetails>
                    }
                </MUIAccordion>
            }
        </div>
    )
};

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, null)(MenuAccordion);
