import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup';
import { getCountries, getGenders, getRelationships } from '../../redux/api/static'
import Webcam from "react-webcam";
import { TbCamera } from 'react-icons/tb'
import { TERTIARY_COLOR } from '../../styles/colors'
import { getBase64FromFile } from '../../util'
import ErrorBox from '../../components/common/ErrorBox'
import { updatePassport, updatePassportFireStore } from '../../redux/api/applicant'

const PersonalDetails = ({ updateApplication, applicationDetails, nextStep, readOnly }) => {
    const [ showCamera, setShowCamera ] = useState(false)
    const [ uploadProcess, setUploadProcess ] = useState(false)
    const webCamRef = useRef(null)
    const imageInputRef = useRef(null)
    const defaultVal = {
        nationality: '', country_of_birth: '',
        passport: '',
        address: '', dob: '',
        emergency_name: '', emergency_email: '', emergency_phone: '', emergency_address: '', emergency_relationship: '',
        // referral: '',
        english_proficiency: ''
    };


    const handleImageSelection = async ({ target }) => {
        if(target?.files[0]) {
            const file = target?.files[0]
            const base64 = await getBase64FromFile(file);
            const string = await updatePassportFireStore({ passport: base64, user_id: applicationDetails.user_id })
            await updatePassport({ passport: string })
        }
    }

    return (
        <Card className='p-2 pt-3 pb-3'>
            <h2 className="text-center">Personal Details</h2>
            <Formik 
                initialValues={{ ...defaultVal, ...applicationDetails }} 
                enableReinitialize
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required('First name is required.'),
                    english_proficiency: Yup.string().required('English proficiency is required.'),
                    country_of_birth: Yup.string().required('Country of birth is required.'),
                    phone: Yup.string().required('Phone number is required.'),
                    gender: Yup.string().required('Gender is required.'),
                    surname: Yup.string().required('Surname is required.'),
                    nationality: Yup.string().required('Nationality is required.'),
                    address: Yup.string().required('Residential address is required.'),
                    dob: Yup.string().required('Date of birth is required.'),

                    emergency_name: Yup.string().required('Emergency name is required.'),
                    emergency_phone: Yup.string().required('Emergency phone is required.'),
                    emergency_email: Yup.string().email('Value must be a valid email address').required('Emergency email is required.'),
                    emergency_relationship: Yup.string().required('Emergency relationship is required.'),
                    emergency_address: Yup.string().required('Emergency address is required.'),
                })}
                onSubmit={async (values) => updateApplication(values, 'personal')}

            >
                {
                    ({ isSubmitting, values, errors }) => (
                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={2}>
                                <>
                                    <Grid item xs={12} container alignItems={'center'} justifyContent={'center'} flexDirection='column'>
                                        <Box 
                                            style={{ width: '250px', height: '250px', border: '1px solid grey', borderRadius: '5px' }}
                                            display='flex' justifyContent={'center'} alignItems='center'
                                        >
                                            {
                                                Boolean(showCamera) ?
                                                <div>
                                                    <Webcam 
                                                        height={250}
                                                        width={250}
                                                        ref={webCamRef}
                                                        >
                                                        {({ getScreenshot }) => (
                                                            <Button fullWidth disabled={uploadProcess}
                                                                style={{ marginTop: '-3.5rem' }}
                                                                onClick={() => {
                                                                    const imageSrc = getScreenshot();
                                                                    // uploadImage(imageSrc)
                                                                }}
                                                            >
                                                                { uploadProcess ? 'Processing...' : 'Take Phone' }
                                                            </Button>
                                                        )}
                                                        </Webcam>

                                                </div>
                                                :
                                                Boolean(uploadProcess) ?
                                                <p className='text-center'>
                                                    <CircularProgress size={45} /><br />
                                                    <small>Uploading Passport...</small>
                                                </p>
                                                :
                                                Boolean(values.passport) ?
                                                <img src={values.passport} alt='passport' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }} />
                                                :
                                                <p className='text-center'>
                                                    <TbCamera size={50} /><br />
                                                    <small>Upload Passport</small>
                                                </p>
                                            }
                                        </Box>
                                        {
                                            !(readOnly) &&
                                            <Box className='mt-05 mb-2'>
                                                <input hidden type='file' accept='image/*' ref={imageInputRef} onChange={handleImageSelection} />
                                                <Button disabled={uploadProcess} onClick={() => setShowCamera(!showCamera)} variant="contained" className="mr-05">Camera</Button>
                                                <Button disabled={uploadProcess} onClick={() => imageInputRef?.current?.click()} variant="contained" className="ml-05" style={{ backgroundColor: TERTIARY_COLOR, color: '#fff' }}>File</Button>
                                            </Box>
                                        }
                                        <ErrorBox errors={errors['passport'] || ''} />
                                    </Grid>
                                </>

                                <>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="surname" label="Surname" required placeholder="Enter surname" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="first_name" label="First Name" required placeholder="Enter first name" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="other_names" label="Other Name(s)" placeholder="Enter other names" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="dob" label="Date of birth" placeholder="Select date" type="date" required readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput errors={errors} id="gender" label="Gender" required placeholder="Select gender" 
                                            selectFunction={getGenders} field='code' fieldDisplay='desc' readOnly={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="phone" label="Phone Number" required placeholder="Enter phone number" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput errors={errors} required selectFunction={getCountries} fieldDisplay='desc' field='code' id="nationality" label="Nationality" placeholder="Select a country" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput errors={errors} required selectFunction={getCountries} fieldDisplay='desc' field='code' id="country_of_birth" label="Country of Birth" placeholder="Select a country" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput errors={errors} required id="english_proficiency"  readOnly={readOnly} field='code' fieldDisplay='code' label="English Proficiency" options={[{ code: 'high' }, { code: 'medium' }, { code: 'low' }]} placeholder="Select an option" />
                                    </Grid> 
                                    {/* <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput id="referral" label="Referral" options={[]} placeholder="Select an option" />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <TextInput errors={errors} required id="address" placeholder="Enter residential address" label="Address" minRows={2} multiline readOnly={readOnly} />
                                    </Grid>
                                </>

                                <>
                                    <Grid item xs={12}>
                                        <h2 className='mb-0 mt-1 text-center'>Contact Information</h2>
                                        <p className='text-center mt-0 mb-0'>Kindly fill in an emergency contact</p>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="emergency_name" required label="Emergency Full Name" placeholder="Enter full name" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="emergency_phone" required label="Emergency Phone Number" placeholder="Enter phone number" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TextInput errors={errors} id="emergency_email" required label="Emergency Email" placeholder="Enter email address" readOnly={readOnly} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SelectInput errors={errors} id="emergency_relationship" readOnly={readOnly} fieldDisplay='desc' required label="Emergency Relationship" placeholder="Select a relationship" selectFunction={getRelationships} field='code' />
                                    </Grid>
                                    <Grid item xs={12} lg={8}>
                                        <TextInput errors={errors} id="emergency_address" readOnly={readOnly} required multiline label="Emergency Address" placeholder="Enter residential address" />
                                    </Grid>
                                </>
                                <Grid item xs={12} container spacing={3} className="mt-05">
                                    <Grid item xs={12} md={6}>
                                        <Button variant='contained' color={"secondary"} onClick={() => nextStep(0)} fullWidth>
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {
                                            !(readOnly) ?
                                            <Button variant='contained' type='submit' color="primary" fullWidth>
                                                { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Update & Proceed' }
                                            </Button>
                                            :
                                            <Button variant='contained' onClick={() => nextStep(2)} color="primary" fullWidth>
                                                Proceed
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                } 
            </Formik>
        </Card>
    )
}

export default PersonalDetails;