import { AccountTreeOutlined, Add, EditOutlined, ZoomInMap } from '@mui/icons-material';
import { Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Tab, Tabs } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import SelectInput from '../../components/common/SelectInput';
import TextInput from '../../components/common/TextInput';
import DashboardContainer from '../../components/DashboardContainer'
import { addOrUpdateRole, rolePrivilegeMapping, addOrUpdatePrivilege, getPrivileges, getRoles } from '../../redux/api/userMgt';

const TabValues = ["Roles", "Permissions"];

const RolesPrivileges = () => {

    const defaultPrivilege = { 
        name: "", description: "", "newCode": ""
    }
    const defaultRole = { 
        "newCode": "",
        "desc": ""
    }
    const [ tabIndex, setTabIndex ] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [ processing, setProcessing ] = useState(false);
    const [ privileges, setPrivileges ] = useState([])
    const [ roles, setRoles ] = useState([])

    const [ selectedRole, setSelectedRole ] = useState(defaultRole)
    const [ selectedPrivilege, setSelectedPrivilege ] = useState(defaultPrivilege)

    const [ openRoleModal, setOpenRoleModal ] = useState(false)
    const [ openPrivilegeModal, setOpenPrivilegeModal ] = useState(false)
    const [ openRolePrivilegesModal, setOpenRolePrivilegesModal ] = useState(false)
    const [ mapping, setMapping ] = useState(false)

    
    const roleColumns = [
        { 
            field: 'sn', headerName: 'S/N', width: 80,
            renderCell: (params) => (
                <p
                    style={{ margin: 'auto' }}
                >
                    {params.api.getRowIndex(params?.row?.code || 0) + 1}
                </p>
            )
        },
        {
            field: 'id', headerName: 'Actions', width: 120, sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        size='small'
                        color="primary"
                        variant="outlined" title='Edit Role'
                        onClick={(e) => { setSelectedRole(params.row); setOpenRoleModal(true) }}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        size='small'
                        color="primary"
                        variant="outlined" title='Map Privileges to Role'
                        onClick={(e) => { setSelectedRole(params.row); setOpenRolePrivilegesModal(true) }}
                    >
                        <AccountTreeOutlined />
                    </IconButton>
                </div>
            )
        },
        { field: 'code', headerName: 'Code', width: 150  },
        { field: 'desc', headerName: 'Name', width: 300  },
        // { field: 'description', headerName: 'Description', width: 450 },
        { field: 'roleType', headerName: 'Role Type', width: 300,  },
        { field: 'dateCreated', headerName: 'Creation Date', width: 200, valueGetter: ({ rows }) => rows?.dateCreated ? moment(rows?.dateCreated).format('DD/MM/YYYY hh:mm:ss') : '' },
    ];
    
    const privilegeColumns = [
        { 
            field: 'sn', headerName: 'S/N', width: 80,
            renderCell: (params) => (
                <p
                    style={{ margin: 'auto' }}
                >
                    {params.api.getRowIndex(params?.row?.code || 0) + 1}
                </p>
            )
        },
        {
            field: 'id', headerName: 'Actions', width: 100, sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        size='small'
                        color="primary"
                        variant="outlined" title='Edit Role'
                        onClick={(e) => { setSelectedPrivilege(params.row); setOpenPrivilegeModal(true) }}
                    >
                        <EditOutlined />
                    </IconButton>
                </div>
            )
        },
        { field: 'code', headerName: 'Code', width: 200  },
        { field: 'name', headerName: 'Name', width: 200  },
        { field: 'desc', headerName: 'Description', flex: 1 },
    ];
    
    const submitRole = async (values) => {
        try {
            const res = await addOrUpdateRole(values);
            enqueueSnackbar(res?.message || `Role ${values?.id ? 'updated' : 'created'} successfully`);
            init();
            setOpenRoleModal(false)
            setSelectedRole(defaultRole)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    const submitPrivilege = async (values) => {
        try {
            const res = await addOrUpdatePrivilege(values);
            enqueueSnackbar(res?.message || `Privilege ${values?.seQ_ID ? 'updated' : 'created'} successfully`);
            init();
            setOpenPrivilegeModal(false)
            setSelectedPrivilege(defaultPrivilege)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    const handleChange = (event, value) => {
        let rolePrivileges = selectedRole?.privileges || [];
        if(value) {
            rolePrivileges.push(event.target.name)
        } else {
            rolePrivileges = rolePrivileges.filter((pri) => pri != event.target.name)
        }
        setSelectedRole({ ...selectedRole, privileges: rolePrivileges })
    };

    const hasPrivilege = (privilege_id) => {
        return (selectedRole?.privileges?.map(x => String(x))?.indexOf(String(privilege_id)) >= 0) ? true : false
    }

    const handleMapping = async () => {
        try {
            setMapping(true)
            await rolePrivilegeMapping({ roleId: selectedRole?.code, privileges: selectedRole?.privileges || [] })
            setSelectedRole(defaultRole)
            setOpenRolePrivilegesModal(false)
            enqueueSnackbar('Mapping successful', { variant: 'success' })
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setMapping(false)
        }
    }

    const init = async () => {
        try {
            setProcessing(true)
            const res = await getRoles();
            const resp = await getPrivileges();

            setRoles(res)
            setPrivileges(resp)
        }
        catch(err) {

        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <DashboardContainer pageTitle='Roles &amp; Permissions' processing={processing}>
            <Card className='custom-card'>
                <Tabs value={tabIndex} onChange={(e, val) => setTabIndex(val)}>
                    { TabValues.map((value, key) => <Tab label={value} key={key} />) }
                </Tabs>

                {
                    tabIndex == 0 &&
                    <div style={{ textAlign: 'right' }}>
                        <Button className="mt-05 mb-1" startIcon={<Add />} variant="contained" onClick={(e) => { setSelectedRole(defaultRole); setOpenRoleModal(true) }}>Add Role</Button>
                        <Box mb={5}>
                            <div style={{ height: 550, width: '100%', textAlign: 'center' }} className='data-table'>
                                <DataGrid
                                    rows={roles}
                                    columns={roleColumns}
                                    getRowId={row => row.code}
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                />
                            </div>
                        </Box>
                    </div>
                }

                {
                    tabIndex == 1 &&
                    <div style={{ textAlign: 'right' }}>
                        <Button className="mt-05 mb-1" startIcon={<Add />} variant="contained" onClick={(e) => { setSelectedPrivilege(defaultPrivilege); setOpenPrivilegeModal(true) }}>Add privilege</Button>
                        <Box mb={5}>
                            <div style={{ height: 550, width: '100%', textAlign: 'center' }} className='data-table'>
                                <DataGrid
                                    rows={privileges}
                                    columns={privilegeColumns}
                                    getRowId={row => row.code}
                                    checkboxSelection={false}
                                    disableSelectionOnClick
                                />
                            </div>
                        </Box>
                    </div>
                }
            </Card>

            <Dialog open={openRoleModal}>
                <Formik initialValues={{ ...selectedRole, newCode: selectedRole?.code || ''}} enableReinitialize onSubmit={submitRole}>
                    {
                        ({ isSubmitting }) => (
                            <Form>
                                <DialogContent className="p-2">
                                    <h3 className="f-bold f-22 mb-1">
                                        {
                                            Boolean(selectedRole?.code) ? 'Edit ' : 'Add '
                                        }
                                        Role Details
                                    </h3>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextInput id="newCode" label="Role Code" required />    
                                        </Grid> 
                                        <Grid item xs={12}>
                                            <TextInput id="desc" label="Role Name" required />    
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className="pr-2 pb-2">
                                    <Button 
                                        onClick={() => {
                                            setOpenRoleModal(false);
                                            setSelectedRole(defaultRole)
                                        }} 
                                        variant='contained' color="secondary" disabled={isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button variant='contained' type="submit" disabled={isSubmitting}>
                                        { isSubmitting ? <CircularProgress color="inherit" size={15} /> : Boolean(selectedRole?.id) ? 'Edit Role' : 'Add Role' }
                                    </Button>
                                </DialogActions>
                            </Form>
                        )
                    }
                </Formik>
            </Dialog>

            <Dialog open={openPrivilegeModal}>
                <Formik initialValues={{ ...selectedPrivilege, newCode: selectedPrivilege?.code || ''}} enableReinitialize onSubmit={submitPrivilege}>
                    {
                        ({ isSubmitting }) => (
                            <Form>
                                <DialogContent className="p-2">
                                    <h3 className="f-bold f-22 mb-1">
                                        {
                                            Boolean(selectedPrivilege?.seQ_ID) ? 'Edit ' : 'Add '
                                        }
                                        Privilege Details
                                    </h3>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextInput id="newCode" label="Role Code" required />    
                                        </Grid> 
                                        <Grid item xs={12}>
                                            <TextInput id="name" label="Role Name" required />    
                                        </Grid> 
                                        <Grid item xs={12}>
                                            <TextInput id="desc" label="Role Description" multiline minRows={2} />    
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions className="pr-2 pb-2">
                                    <Button 
                                        onClick={() => {
                                            setOpenPrivilegeModal(false);
                                            setSelectedPrivilege(defaultRole)
                                        }} 
                                        variant='contained' color="secondary" disabled={isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button variant='contained' type="submit" disabled={isSubmitting}>
                                        { isSubmitting ? <CircularProgress color="inherit" size={15} /> : Boolean(selectedPrivilege?.seQ_ID) ? 'Edit Privilege' : 'Add Privilege' }
                                    </Button>
                                </DialogActions>
                            </Form>
                        )
                    }
                </Formik>
            </Dialog>

            <Dialog open={openRolePrivilegesModal} maxWidth='lg' PaperProps={{ style: { minWidth: '35vw' } }}>
                <DialogTitle className="p-1 pb-0">
                    <span className="f-bold f-22 m-0">
                        Map Privileges to {selectedRole?.desc}
                    </span>
                </DialogTitle>
                <DialogContent className="pr-2 pl-2">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" variant="standard" style={{ width: '100%' }}>
                                <FormGroup>
                                    {
                                        privileges.map(({ code, name, desc }, key) => (
                                            <div key={key} className="mt-1">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={hasPrivilege(code)} onChange={handleChange} name={code}  />
                                                    }
                                                    label={
                                                        <>
                                                            <span className='d-block'>{name}</span>
                                                            <small>{desc}</small>
                                                        </>
                                                    } key={key}
                                                    
                                                />
                                                <Divider className='mt-05' />
                                            </div>
                                        ))
                                    }
                                </FormGroup>
                            </FormControl>
                        </Grid> 
                    </Grid>
                </DialogContent>
                <DialogActions className="pr-2 pb-2 pt-1">
                    <Button 
                        onClick={() => {
                            setOpenRolePrivilegesModal(false);
                            setSelectedRole(defaultRole)
                        }} 
                        variant='contained' color="secondary" disabled={mapping}
                    >
                        Close
                    </Button>
                    <Button variant='contained' disabled={mapping} onClick={handleMapping}>
                        { mapping ? <CircularProgress color="inherit" size={15} /> : 'Save Mapping' }
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardContainer>
    )
}

export default RolesPrivileges