import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyA6d4w0BzdNZ8XarYxiMktixvsQ6_lQbk8",
    authDomain: "aaipsds-4b76d.firebaseapp.com",
    projectId: "aaipsds-4b76d",
    storageBucket: "aaipsds-4b76d.appspot.com",
    messagingSenderId: "649439176932",
    appId: "1:649439176932:web:b3eff54d1f1e9e247824f1",
    measurementId: "G-Q8DMJ96DGH"
};

const app = initializeApp(firebaseConfig);

export default app;
