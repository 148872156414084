import store from "../redux/store";
import { PRIMARY_COLOR, SUCCESS_COLOR, WARNING_COLOR } from "../styles/colors";

String.prototype.capitalize = function(string = '') {
    const str = string.toLowerCase();
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2
}

export const capitalize = (string = "") => {
    return String().capitalize(string);
} 


export const getPrivilegeStatus = (privilege = '') => {
    const user = store?.getState()?.user?.userData || {}
    const privileges = user?.privileges || []
    if(typeof(privilege) === 'string') {
        return Boolean(privileges.find(id => privilege === id)) ? true : false
    } else {
        return Boolean( privilege.map((priv) => Boolean(privileges.find(id => priv === id))).reduce((prev, nex) => prev || nex)) ? true : false
    }
}


// export const getFullName = (first_name = '', last_name = '', middle_name = '') => {
//     return String().capitalize(`${last_name || ''} ${first_name || ''} ${middle_name || '   '}`).trim();
// }

export const getColor = (color) => {
    //   'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning',
    switch (color) {    
        case 'pending':
            return 'warning'
        case 'probation':
            return 'warning'
        case 'initiated':
            return 'warning'
        case 'submitted':
            return 'info'
        case 'approved':
            return 'success'
        case 'granted':
            return 'success'
        case 'accepted':
            return 'success'
        case 'completed':
            return 'success'
        case 'active':
            return 'success'
        case 'declined':
            return 'error'
        case 'rejected':
            return 'error'
        case 'expelled':
            return 'error'
        default:
            return 'default'
    }
}

export const getHexColor = (color) => {
    //   'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning',
    switch (color) {    
        case 'pending':
            return WARNING_COLOR
        case 'probation':
            return WARNING_COLOR
        case 'initiated':
            return WARNING_COLOR
        case 'submitted':
            return PRIMARY_COLOR
        case 'approved':
            return SUCCESS_COLOR
        case 'granted':
            return SUCCESS_COLOR
        case 'accepted':
            return SUCCESS_COLOR
        case 'completed':
            return SUCCESS_COLOR
        case 'active':
            return SUCCESS_COLOR
        case 'declined':
            return 'brown'
        case 'rejected':
            return 'brown'
        case 'expelled':
            return 'brown'
        case 'inactive':
            return 'brown'
        default:
            return ''
    }
}

export const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
        try {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject('Error: ' + error);
            };
        }
        catch(err) {
            reject(err.message)
        }
    })
}

export const openFileInNewBrowser = (file, isUrl = false) => {
    const blobUrl = isUrl ? file : URL.createObjectURL(file);
    window.open(blobUrl, '_blank');
}