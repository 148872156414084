import DashboardContainer from '../../components/DashboardContainer'
import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box, capitalize, Divider } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import RemitaPayment from 'react-remita'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import { getProgrammes, getProgrammesCategories } from '../../redux/api/static'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import appRoutes from '../../routes/appRoutes'
import { updateApplicantDetails } from '../../redux/actions/userActions'
import { bindActionCreators } from 'redux'
import { confirmApplicantPayments, confirmPayment, getApplicationPaymentDetails } from '../../redux/api/applicant'
import Loader from '../../components/common/Loader'

const Payment = ({ applicationDetails, updateApplication, nextStep, init, readOnly }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ paymentData, setPaymentData ] = useState({})
    const [ processing, setProcessing ] = useState(false);
    const [ fetching, setFetching] = useState(false);
    const [ confirming, setConfirming ] = useState(false);

    const confirmStatus = async (rrr, orderId) => {
        try {
            await confirmPayment(rrr, orderId);
        }
        catch {

        }
    }

    const getPaymentDetails = async (id) => {
        try {
            setFetching(true)
            const details = await getApplicationPaymentDetails(id);
            setPaymentData(details)

            if(details?.paymentDetails?.application?.status !== "paid") {
                const resp = await confirmPayment(details?.paymentDetails?.rrr)
                if(resp?.status == "00" && resp?.message == "Successful") {
                    await updateApplication({
                        ...details?.paymentDetails?.application, app_no: applicationDetails?.app_no || '',
                        remitaResponse: { ...resp, status: resp?.status === '00' ? "paid" : "pending" }
                    }, 'payment');
                    // setPaymentData({ ...details?.paymentDetails, status: 'paid' })
                }
            }
            if(details?.paymentDetails?.courseFee?.status !== "paid") {
                const resp = await confirmPayment(details?.paymentDetails?.courseFee?.rrr)
                if(resp?.status == "00" && resp?.message == "Successful") {
                    await updateApplication({
                        ...details?.paymentDetails?.courseFee, app_no: applicationDetails?.app_no || '',
                        remitaResponse: { ...resp, status: resp?.status === '00' ? "paid" : "pending" }
                    }, 'payment');
                    // setPaymentData({ ...details?.paymentDetails, status: 'paid' })
                }
            }
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setFetching(false)
        }
    }

    const confirmPayments = async (id) => {
        try {
            setConfirming(true)
            const resp = await confirmApplicantPayments({ app_no: applicationDetails?.app_no || '', app_id: applicationDetails?.id || '' });
            if(resp?.status == 200) {
                await init(applicationDetails?.id || '')
            }
            else {
                enqueueSnackbar(resp?.message || 'An error occurred', { variant: 'error' })
            }
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setConfirming(false)
        }
    }
// Application Fee
// 
    const makePayment = (type, paymentData) => {
        const RmPaymentEngine = window?.RmPaymentEngine;
        if(RmPaymentEngine) {
            const paymentEngine = RmPaymentEngine.init({
                key: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REMITA_KEY : process.env.REACT_APP_REMITA_LIVE_KEY, // enter your key here
                customerId: paymentData?.app_no || '',
                firstName: applicationDetails?.first_name || '',
                lastName: applicationDetails?.surname || '',
                email: applicationDetails?.email || '',
                amount: (paymentData?.amount || 0) + (paymentData?.charge || 0),
                rrr: paymentData?.rrr || '',
                narration: `${type} - Application No - ${paymentData.app_no} - Programme - ${paymentData?.programme}`,
                extendedData: { 
                    customFields: [ 
                       { 
                          name: "rrr", 
                          value: paymentData?.rrr || '',
                       } 
                     ]
                },
                processRrr: true,
                onSuccess: async (response) => {
                    setProcessing(true)
                    setTimeout(async () => {
                        const resp = await confirmPayment(response?.paymentReference)
                        await updateApplication({
                            ...paymentData, remitaResponse: { ...resp, status: resp?.status === '00' ? "paid" : "pending" }
                        }, 'payment')
                        // setPaymentData({ ...paymentData, status: resp?.status === '00' ? "paid" : "pending"  })
                        setProcessing(false)
                    }, 15000)
                },
                onError: function (response) {
                    enqueueSnackbar('Payment unable to complete', { variant: 'error' })
                    setProcessing(false)
                },
                onClose: function () {
                    // enqueueS÷nackbar('Payment Cancelled', { variant: 'error' })
                },
            });
            paymentEngine.showPaymentWidget();
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        if(applicationDetails.id) {
            getPaymentDetails(applicationDetails.id);
        }
    }, [applicationDetails])

    return (
        <Card className='p-2 pt-3 pb-3'>
            { fetching && <Loader />}
            <h2 className="text-center">Payment Details</h2>
            <Formik 
                initialValues={{ ...paymentData }} 
                enableReinitialize
                validationSchema={Yup.object().shape({
                    // programme_category: Yup.string().required('Programme category is required.'),
                    // programme_name: Yup.string().required('Programme is required.'),
                })}
            >
                {
                    ({ isSubmitting, values, errors, setFieldValue }) => (
                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={2} alignItems='flex-end'>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        required 
                                        readOnly={true}
                                        id="app_no" 
                                        label="Application Number"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="programme_category" 
                                        label="Programme Category"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="programme_name" 
                                        label="Programme"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="programme_duration" 
                                        label="Programme Duration"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className='mt-1' />
                                    <h3 className='text-center m-0 mt-1'>Application Fee</h3>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true} 
                                        valueFirst 
                                        value={(parseFloat(values?.paymentDetails?.application?.amount) == 0 ? 'Free' : values?.paymentDetails?.application?.amount)}
                                        id="programme_app_fee" 
                                        label="Programme Application Fee"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required
                                        readOnly={true} capitalizeValue
                                        id="paymentDetails.application.status" 
                                        label="Payment Status"
                                    />
                                </Grid>
                                {
                                    (values?.paymentDetails?.application?.rrr && (parseFloat(values?.paymentDetails?.application?.amount) > 0)) &&
                                    <Grid item xs={12} md={6}>
                                        <TextInput 
                                            readOnly={true}
                                            id="paymentDetails.application.rrr" 
                                            label="RRR"
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    {
                                        (values?.paymentDetails?.application?.status === "initiated") &&
                                        <Button variant='contained' onClick={async () => await makePayment('Application Fee', { ...values?.paymentDetails?.application, app_no: values?.app_no || ''})} color="success" fullWidth disabled={processing}>
                                            { processing ? <CircularProgress size={15} color='inherit' /> : 'Pay Application Fee' }
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                    <h3 className='text-center m-0 mt-1'>Course Fee</h3>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        valueFirst 
                                        value={(parseFloat(values?.paymentDetails?.courseFee?.amount || 0 + values?.paymentDetails?.courseFee?.charge || 0) == 0 ? 'Free' : values?.paymentDetails?.courseFee?.amount || 0 +  values?.paymentDetails?.courseFee?.charge || 0)}
                                        id="programme_course_fee" 
                                        label="Programme Course Fee"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required
                                        readOnly={true} capitalizeValue
                                        id="paymentDetails.courseFee.status" 
                                        label="Payment Status"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        readOnly={true}
                                        id="paymentDetails.courseFee.rrr" 
                                        label="RRR"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        (values?.paymentDetails?.courseFee?.status === "initiated") &&
                                        <Button variant='contained' onClick={async () => await makePayment('Course Fee', { ...values?.paymentDetails?.courseFee, app_no: values?.app_no || ''})} color="success" fullWidth disabled={processing}>
                                            { processing ? <CircularProgress size={15} color='inherit' /> : 'Pay Course Fee' }
                                        </Button>
                                    }
                                </Grid>
                                <Grid item xs={12} container className="text-center mt-05" spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Button variant='contained' color={"secondary"} onClick={() => nextStep(3)} fullWidth>
                                            Previous
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {
                                            (((values?.paymentDetails?.application?.status === "paid" && values?.paymentDetails?.courseFee?.status == "paid") && applicationDetails.app_step > 4) || readOnly) ?
                                            <Button variant='contained' onClick={() => nextStep(5)} color="primary" fullWidth>
                                                Proceed
                                            </Button>
                                            :
                                            ((values?.paymentDetails?.application?.status === "paid" && values?.paymentDetails?.courseFee?.status == "paid")  && applicationDetails.app_step == 4) ?
                                            <Button variant='contained' disabled={confirming} onClick={async () => await confirmPayments()} color="primary" fullWidth>
                                                { confirming ? <CircularProgress size={12} color='inherit' /> : "Confirm Payment and Proceed" }
                                            </Button>
                                            :
                                            (values?.paymentDetails?.application?.status === "initiated" || values?.paymentDetails?.courseFee?.status == "initiated") ?
                                            <></>
                                            :
                                            <></>
                                        }
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Form>
                    )
                } 
            </Formik>
        </Card>
    )
}

export default Payment