import axios from 'axios'
import store from '../store';
const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:9005/api/v1' : process.env.REACT_APP_NODE_SERVER;

const $axios = axios.create({
    baseURL,
});

$axios.interceptors.request.use((config) => {
    const user = store?.getState()?.user?.userData || {}
    config.headers['Authorization'] = user?.token || ''
    config.headers['user_id'] = user?.user_id || '';
    
    return config
})

$axios.interceptors.response.use(
    request => {
        return Promise.resolve(request?.data)
    },
    error => {
        if(error?.response?.status === 401 || error?.status === 401) {
            localStorage.removeItem('aaipsds-user')
            window.location.href = '/login'
        } else {
            return Promise.reject(error?.response?.data || 'An error occurred')
        }
    }
)

export { $axios };