import { Grid } from "@mui/material";
import { getCountries, getGenders } from "../redux/api/static";
import SelectInput from "../components/common/SelectInput";
import TextInput from "../components/common/TextInput";
import { getAllRoles, getRoles } from "../redux/api/userMgt";

export const userTypes = [
    { code: 'applicant', label: 'Applicant' },
    { code: 'staff', label: 'Staff' },
    { code: 'student', label: 'Student' }
]

export const ApplicantFormData = () => (
    <>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="surname" label="Surname" required placeholder="Enter surname" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="first_name" label="First Name" required placeholder="Enter first name" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="other_names" label="Other Name(s)" placeholder="Enter other names" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="gender" label="Gender" required placeholder="Select gender" 
                selectFunction={getGenders} field='code' fieldDisplay='desc'
            />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="dob" label="Date of Birth" required type="date"/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="phone" label="Phone Number" required placeholder="Enter phone number" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="nationality" label="Nationality" field="code" fieldDisplay='desc' selectFunction={getCountries}  placeholder="Select a country" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="country_of_birth" label="Country of Birth" field="code" fieldDisplay='desc' selectFunction={getCountries} placeholder="Select a country" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="email" autoComplete='new-password' required label="Email Address" placeholder="Enter email address" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="confirmEmail" autoComplete='new-password' required label="Confirm Email Address" placeholder="Confirm email address" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="password" autoComplete='new-password' required label="Password" type="password" placeholder="Enter password" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="confirmPassword" autoComplete='new-password' required label="Confirm Password" type="password" placeholder="Confirm password" />
        </Grid>
        <Grid item xs={12}>
            <TextInput id="address" required label="Address" multiline minRows={2} placeholder="Enter address" />
        </Grid>
    </>
)

export const StudentFormData = () => (
    <>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="surname" label="Surname" required placeholder="Enter surname" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="first_name" label="First Name" required placeholder="Enter first name" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="other_names" label="Other Name(s)" placeholder="Enter other names" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="gender" label="Gender" required placeholder="Select gender" 
                selectFunction={getGenders} field='code' fieldDisplay='desc'
            />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="dob" label="Date of Birth" required type="date"/>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="phone" label="Phone Number" required placeholder="Enter phone number" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="nationality" label="Nationality" field="code" fieldDisplay='desc' selectFunction={getCountries}  placeholder="Select a country" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="country_of_birth" label="Country of Birth" field="code" fieldDisplay='desc' selectFunction={getCountries} placeholder="Select a country" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="email" autoComplete='new-password' required label="Email Address" placeholder="Enter email address" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="confirmEmail" autoComplete='new-password' required label="Confirm Email Address" placeholder="Confirm email address" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="password" autoComplete='new-password' required label="Password" type="password" placeholder="Enter password" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="confirmPassword" autoComplete='new-password' required label="Confirm Password" type="password" placeholder="Confirm password" />
        </Grid>
        <Grid item xs={12}>
            <TextInput id="address" required label="Address" multiline minRows={2} placeholder="Enter address" />
        </Grid>
    </>
)

export const StaffFormData = ({ isEdit }) => (
    <>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="role" label="Role" required placeholder="Select Role" 
                selectFunction={getAllRoles} field='code' fieldDisplay='desc'
            />
        </Grid>
        {
            isEdit &&
            <Grid item xs={12} md={6} lg={4}>
                <TextInput id="username" label="Username" required />
            </Grid>
        }
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="title" label="Title" placeholder="Enter title" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="surname" label="Surname" required placeholder="Enter surname" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="first_name" label="First Name" required placeholder="Enter first name" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="other_names" label="Other Name(s)" placeholder="Enter other names" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="email" autoComplete='new-password' required label="Email Address" placeholder="Enter email address" />
        </Grid>
        {
            !isEdit &&
            <Grid item xs={12} md={6} lg={4}>
                <TextInput id="confirmEmail" autoComplete='new-password' required label="Confirm Email Address" placeholder="Confirm email address" />
            </Grid>
        }
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="gender" label="Gender" placeholder="Select gender" 
                selectFunction={getGenders} field='code' fieldDisplay='desc'
            />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextInput id="phone" label="Phone Number" placeholder="Enter phone number" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <SelectInput id="nationality" label="Nationality" field="code" fieldDisplay='desc' selectFunction={getCountries}  placeholder="Select a country" />
        </Grid>
        <Grid item xs={12}>
            <TextInput id="address" label="Address" multiline minRows={2} placeholder="Enter address" />
        </Grid>
    </>
)