import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Page404 from "../pages/Page404";
import { StudentDashboardPage } from "../pages/student";

const StudentRoutes = () => {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${path}/dashboard`} />
            </Route>

            <Route exact path={`${path}/website-resources`}>
                <Redirect to={`${path}/website-resources/news`} />
            </Route>

            <Route exact path={`${path}/dashboard`} component={StudentDashboardPage} />

            <Route path="**">
                <Page404 />
            </Route>
        </Switch>
    )
}

export default StudentRoutes
