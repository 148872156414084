import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/DashboardContainer';
import { useSnackbar } from 'notistack'
import { getApplications } from '../../redux/api/applicant';
import { Button, Grid, Box, IconButton, Chip, capitalize } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import appRoutes from '../../routes/appRoutes'
import { DataGrid } from '@mui/x-data-grid';
import { Edit, VisibilityOutlined } from '@mui/icons-material';
import { getColor } from '../../util';
import moment from 'moment'

const Dashboard = () => {
    const [ processing, setProcessing ] = useState(false)
    const [ applications, setApplications ] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()


    const columns = [
        { 
            field: 'sn', headerName: 'S/N', width: 80,
            renderCell: (params) => (
                <p
                    style={{ margin: 'auto' }}
                >
                    {params.api.getRowIndex(params?.row?.id) + 1}
                </p>
            )
        },
        // { field: 'sn', headerName: 'S/N', width: 100, valueGetter: (params) => (params?.api?.getRowIndex(params?.row?.id)  || 0) + 1 },
        {
            field: 'id', headerName: 'Quick Actions', width: 200, sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    {
                        (params?.row?.app_status === 1) &&
                        <Button
                            size='small' className='mr-05'
                            color="primary" startIcon={<Edit />}
                            variant="outlined" title='Edit Application' 
                            onClick={() => history.push(`${appRoutes.applicant.applications}/${params?.row?.id}`)}
                        >
                            Edit
                        </Button>
                    }

                    {
                        (params?.row?.app_status !== 2) &&
                        <Button
                            size='small'  className='ml-05'
                            color="primary" startIcon={<VisibilityOutlined />}
                            variant="outlined" title='View Application'
                            onClick={() => history.push(`${appRoutes.applicant.applications}/${params?.row?.id}`)}
                        >
                            
                            View
                        </Button>
                    }
                </div>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <div><Chip style={{ fontSize: 12 }} size='small' color={getColor(params?.row?.status || '')} label={capitalize(params?.row?.status || '')} /></div>
            ),
        },
        { field: 'app_no', headerName: 'Application No.', width: 200 },
        { field: 'programme_name', headerName: 'Programme.', width: 250 },
        { field: 'programme_category_name', headerName: 'Category', width: 250 },
        {
            field: 'app_step',
            headerName: 'Application Step',
            width: 200,
        },
        {
            field: 'created_at',
            headerName: 'Creation Date',
            width: 250,
            valueGetter: (params) => moment(params.row.created_at).format('DD/MM/YYYY - HH:MM:SS A'),
        },
    ];

    const init = async () => {
        try {
            setProcessing(true)
            const res = await getApplications()
            setApplications(res)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <DashboardContainer pageTitle='Applicant Dashboard' processing={processing}>
            {
                Boolean(applications.length > 0) ?
                <>
                    <Box my={5}>
                        <div style={{ height: 550, width: '100%', textAlign: 'center' }} className='data-table'>
                            <DataGrid
                                rows={applications}
                                columns={columns}
                                // pageSize={10}
                                // rowsPerPageOptions={[10]}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />
                        </div>
                    </Box>
                </>
                :
                <>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{ margin: 'auto' }} textAlign={'center'}>
                            <img style={{ width: '70%' }} src={`${process.env.PUBLIC_URL}/assets/images/start.svg`} alt="start image" />
                            <h3 className='mb-0'>Start New Application</h3>
                            <p className='mt-05 mb-05'>No application process running, click the button below to start a new application</p>
                            <Link to={appRoutes.applicant.new}>
                                <Button variant='contained'>New Application</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </>
            }
        </DashboardContainer>
    )
}

export default Dashboard