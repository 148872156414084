import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import DashboardContainer from '../../components/DashboardContainer'
import { capitalize, getColor } from '../../util';
import { Add, DeleteOutlined, DoneAll, EditOutlined, FactCheck, FactCheckOutlined, LockResetOutlined, PublishedWithChanges, PublishedWithChangesOutlined } from '@mui/icons-material';
import { Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Filters from '../../components/Filters';
import { createOrUpdateUser, getRoles, getUsers } from '../../redux/api/userMgt';
import { useSnackbar } from 'notistack';
import SelectInput from '../../components/common/SelectInput';
import TextInput from '../../components/common/TextInput';
import { AdminFormData, ApplicantFormData, StaffFormData, StudentFormData, userTypes } from '../../util/userTypeForm';
import appRoutes from '../../routes/appRoutes';

const Users = () => {

    const defaultUser = { 
        "user_type": "",
        "phone": "",
        "email": "",
        "id": ""
    }
    const { enqueueSnackbar } = useSnackbar();
    const [ selectedUser, setSelectedUser ] = useState(defaultUser);
    const [ openDetails, setOpenDetails ] = useState(false);
    const [ searchRes, setSearchRes ] = useState([]);
    const [ processing, setProcessing ] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const location = useLocation();
    const history = useHistory();

    const columns = [
        { 
            field: 'sn', headerName: 'S/N', width: 80,
            renderCell: (params) => (
                <p
                    style={{ margin: 'auto' }}
                >
                    {params.api.getRowIndex(params?.row?.id || 0) + 1}
                </p>
            )
        },
        {
            field: 'id', headerName: 'Quick Actions', width: 150, sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <IconButton
                        size='small'
                        color="primary"
                        variant="outlined" title='Edit Details'
                        onClick={(e) => { history.push(`/internal/users/user/${params?.row?.id}`)}}
                    >
                        <EditOutlined />
                    </IconButton>
                    <IconButton
                        size='small'
                        color="warning"
                        variant="outlined" title='Reset Password'
                        // onClick={(e) => openPopover(e, params)}
                    >
                        <LockResetOutlined />
                    </IconButton>
                </div>
            )
        },
        { field: 'email', headerName: 'Email', width: 300  },
        { field: 'role', headerName: 'Role', width: 200, valueGetter: ({ row }) => {
            const role = roles?.find(({ code }) => code == row?.role);
            return role?.desc || ''
        }},
        { field: 'user_type', headerName: 'User Type', width: 200, valueGetter: (params) => capitalize(params?.row?.user_type)  },
        { field: 'email_verified', headerName: 'Email Verified?', width: 200, valueGetter: (params) => params?.row?.email_verified ? 'True': 'False' },
        { field: 'password_reset', headerName: 'Password Reset?', width: 200, valueGetter: (params) => params?.row?.password_reset ? 'True': 'False' },
        {
            field: 'account_status',
            headerName: 'Account Status',
            width: 200,
            renderCell: (params) => (
                <Chip style={{ fontSize: 11, margin: 'auto' }} size='small' color={getColor(params?.row?.account_status)} label={capitalize(params?.row?.account_status)} />
            ),
        },
        { field: 'created_at', headerName: 'Date Created', width: 200, valueGetter: (params) => params?.row?.created_at ? moment(params?.row?.created_at).format('DD-MM-YYYY hh:mm:ss') : '' },
    ];

    const init = async () => {
        try {
            setProcessing(true)
            const res = await getUsers();
            const roleRes = await getRoles();

            setSearchRes(res)
            setRoles(roleRes)
        }
        catch(err) {

        }
        finally {
            setProcessing(false)
        }
    }

    const submit = async (values) => {
        try {
            const res = await createOrUpdateUser(values);
            enqueueSnackbar(res?.message || `Internal User ${values?.id ? 'Updated' : 'Added'} successfully`);
            init();
            setOpenDetails(false)
            setSelectedUser(defaultUser)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    useEffect(() => {
        init()
    }, [location])

    return (
        <DashboardContainer pageTitle={`All Users`} processing={processing} pageDescription='' buttonComponent={
            <Link to={appRoutes.internal.createUser}>
                <Button startIcon={<Add />} variant="contained">New User</Button>
            </Link>
        }>
            <Card className='custom-card'>
                <Box>
                    <Filters
                        formFields={[
                            { id: 'email', label: 'Email Address' },
                            { id: 'role', label: 'Role', type: 'select', options: roles, field: 'code', fieldDisplay: 'desc' },
                            { id: 'user_type', label: 'User Type', type: 'select', options: [{ code: 'inactive' }, { code: 'active' }], fieldDisplay: 'code', field: 'code' },
                            { id: 'account_status', label: 'Account Status', type: 'select', options: [{ code: 'pending' }, { code: 'active' }], fieldDisplay: 'code', field: 'code' }
                        ]}
                        // searchFunction={searchFunction}
                    />
                </Box>

                <Box my={5}>
                    <div style={{ height: 550, width: '100%', textAlign: 'center' }} className='data-table'>
                        <DataGrid
                            rows={searchRes}
                            columns={columns}
                            getRowId={row => row.id}
                            // pageSize={10}
                            // rowsPerPageOptions={[10]}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </div>
                </Box>
            </Card>


            <Dialog open={openDetails}>
                <Formik initialValues={{ ...selectedUser, financialInstitutionCode: '00001' }} enableReinitialize onSubmit={submit}>
                    {
                        ({ isSubmitting, values }) => (
                            <Form>
                                <DialogContent className="p-2">
                                    <h3 className="f-bold f-22 mb-1">
                                        {
                                            Boolean(selectedUser?.id) ? 'Edit ' : 'Add '
                                        }
                                        Internal User Details
                                    </h3>
                                    <Grid container spacing={3}>
                                        {
                                            Boolean(selectedUser?.id) &&
                                            <Grid item xs={12}>
                                                <TextInput id="userName" label="Username" required />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <SelectInput required options={userTypes} field='code' fieldDisplay="label" id="user_type" label="User Type" />    
                                        </Grid>
                                        { (values?.user_type == 'staff') && <StaffFormData /> }
                                        { (values?.user_type == 'student') && <StudentFormData /> }
                                        { (values?.user_type == 'applicant') && <ApplicantFormData /> }
                                    </Grid>
                                </DialogContent>
                                <DialogActions className="pr-2 pb-2">
                                    <Button 
                                        onClick={() => {
                                            setOpenDetails(false);
                                            setSelectedUser(defaultUser)
                                        }} 
                                        variant='contained' color="secondary" disabled={isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button variant='contained' type="submit" disabled={isSubmitting}>
                                        { isSubmitting ? <CircularProgress color="inherit" size={15} /> : Boolean(selectedUser?.id) ? 'Edit User' : 'Add User' }
                                    </Button>
                                </DialogActions>
                            </Form>
                        )
                    }
                </Formik>
            </Dialog>
        </DashboardContainer>
    )
}

export default Users