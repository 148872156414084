import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../components/DashboardContainer';
import { useSnackbar } from 'notistack'
import { getApplications } from '../../redux/api/applicant';
import { Button, Grid, Box, IconButton, Chip, capitalize, Divider } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import appRoutes from '../../routes/appRoutes'
import { DataGrid } from '@mui/x-data-grid';
import { Edit, ListAlt, VisibilityOutlined } from '@mui/icons-material';
import { getColor } from '../../util';
import moment from 'moment'
import { getAnalytics } from '../../redux/api/internal';
import DashLet from '../../components/common/Dashlet';
import { BLACK_COLOR, ERROR_COLOR, GREEN_COLOR, GREY_COLOR, SECONDARY_COLOR, SUCCESS_COLOR, TERTIARY_COLOR, WARNING_COLOR } from '../../styles/colors';
import { connect } from 'react-redux';
import Moment from 'react-moment';

const darkShade = { fill: 'rgba(0, 0, 0, 0.2)', borderColor: 'rgba(0, 0, 0, 0.2)' }

const Dashboard = ({ user }) => {
    const [ processing, setProcessing ] = useState(false)
    const [ analytics, setAnalytics ] = useState([])
    const [ userData, setUserData ] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const init = async () => {
        try {
            setProcessing(true)
            const res = await getAnalytics()
            setAnalytics(res)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init();
    }, [])

    useEffect(() => {
        setUserData(user?.userData);
    }, [user])

    return (
        <DashboardContainer pageTitle='Student Dashboard' processing={processing}>
            {/* <Grid container spacing={3}> */}
                <div style={{ borderRadius: '10px', padding: '10px 20px', border: '1px solid lightgrey' }}>
                    <Grid container item xs={12} alignItems="center">
                        <Grid item xs={12} sm={6} md={8} lg={9}>
                            <h3 className='mt-05 mb-05'>{(moment().hour() < 12) ? 'Good Morning' : (moment().hour() >= 12 && moment().hour() < 16) ? 'Good Afternoon' : 'Good Evening'}, {capitalize(userData?.first_name || '')}</h3>
                            <small><Moment format='DD MMMM, YYYY | hh:mm:ss A' interval={1000}/></small>
                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} container justifyContent={'flex-end'}>
                            <img src={userData?.passport || ''} style={{ borderRadius: '50%', width: '125px', height: '125px' }} />
                        </Grid>
                    </Grid>
                </div>
            {/* </Grid> */}

            <Divider className="mt-2 mb-2" />

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <DashLet icon={<ListAlt />} label={"Session"} data={"2022/2023"} color={GREY_COLOR} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <DashLet icon={<ListAlt />} label={"Semester"} data={"First"} color={SUCCESS_COLOR}  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <DashLet icon={<ListAlt />} label={"Courses"} data={4} color={TERTIARY_COLOR}  />
                </Grid>
            </Grid>
        </DashboardContainer>
    )
}

export default connect(({ user }) => ({ user }))(Dashboard)