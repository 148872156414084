import React from 'react'
import DashboardContainer from '../../components/DashboardContainer'
import { Step, Stepper, Stack, StepLabel, Card } from '@mui/material'
import { Person, CheckCircleOutlineOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { GiGraduateCap } from 'react-icons/gi'
import { MdPayment } from 'react-icons/md'
import { BsFileText } from 'react-icons/bs'
import { TbBooks } from 'react-icons/tb'
import { ColorlibConnector, ColorlibStepIconRoot } from '../../components/common/StepperProps'
import ProgrammeDetails from './ProgrammeDetails'
import EducationalDetails from './EducationalDetails'
import DocumentUpload from './DocumentUpload'
import Review from './Review'
import PersonalDetails from './PersonalDetails'
import Payment from './Payment'
import { useSnackbar } from 'notistack'
import { getApplicationDetails, updateDocuments, updateEducationDetails, updatePassport, updatePassportFireStore, updatePaymentDetails, updatePersonalDetails, updateProgrammeDetails } from '../../redux/api/applicant'
import { useEffect } from 'react'
import appRoutes from '../../routes/appRoutes'
import { useHistory, useParams } from 'react-router-dom'

const steps = ['Programme Details', 'Personal Details', 'Educational Details', 'Document Upload', 'Payment', 'Review'];

const ColorLibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <GiGraduateCap />,
        2: <Person />,
        3: <TbBooks />,
        4: <GiGraduateCap />,
        5: <MdPayment />,
        6: <BsFileText />,
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            { completed ? <CheckCircleOutlineOutlined /> : icons[String(props.icon)] }
        </ColorlibStepIconRoot>
    )
}

const NewApplication = ({ user }) => {

    const defaultApplication = {
        step: 0
    };
    const history = useHistory()
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar();
    const [ application, setApplication ] = useState(defaultApplication)
    const [ processing, setProcessing ] = useState(false)
    const [ activeStep, setActiveStep ] = useState(0)
    const [ readOnly, setReadOnly ] = useState(true)

    const setStep = (step) => {
        if(step <= (application?.app_step || 0)) {
            setActiveStep(step)
        }
    }

    const updateApplication = async (values, step) => {
        try {
            switch(step) {
                case 'programme':
                    const resp = await updateProgrammeDetails(values);
                    history.push(`${appRoutes.applicant.applications}/${resp?.application?.id}`)
                    break;
                case 'personal':
                    await updatePersonalDetails(values);
                    init(values?.id)
                    break;
                case 'education':
                    await updateEducationDetails(values);
                    init(values?.id)
                    break;
                case 'document':
                    await updateDocuments(values);
                    init(values?.id)
                    break;
                case 'payment':
                    await updatePaymentDetails(values);
                    init(values?.app_id)
                    break;
                default:
                    break;
            }
        }
        catch(err) {
            enqueueSnackbar(err?.message || "An error occurred", { variant: 'error' })
        }
    }

    const init = async (id) => {
        try {
            setProcessing(true);
            const data = await getApplicationDetails(id);
            setApplication(data);
            setReadOnly(!(data?.app_status == 1 || data?.app_status == 0));
            setActiveStep(data?.app_step || 0)
        }
        catch(err) {
            enqueueSnackbar(err?.message || "An error occurred", { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    const uploadImage = async (baseUrl) => {
        try {
            // setUploadProcess(true)
            const url = await updatePassportFireStore({ passport: baseUrl, user_id: application?.applicant?.user_id  });
            const res = await updatePassport({ passport: url  });
            enqueueSnackbar('Passport uploaded successfully')
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            // setUploadProcess(false)
        }
    }

    const getContent = (step) => {
        let index = (step > 5) ? 5 : step;
        switch(index) {
            case 0:
                return <ProgrammeDetails applicationDetails={application} updateApplication={updateApplication} nextStep={setStep} readOnly={readOnly} />
            case 1:
                return <PersonalDetails applicationDetails={application} updateApplication={updateApplication} nextStep={setStep} readOnly={readOnly} />
            case 2:
                return <EducationalDetails applicationDetails={application} updateApplication={updateApplication} nextStep={setStep} readOnly={readOnly} />
            case 3:
                return <DocumentUpload applicationDetails={application} updateApplication={updateApplication} nextStep={setStep} readOnly={readOnly} />
            case 4:
                return <Payment applicationDetails={application} updateApplication={updateApplication} init={init} nextStep={setStep} readOnly={readOnly} />
            case 5:
                return <Review applicationDetails={application} updateApplication={updateApplication} readOnly={readOnly} />
            default:
                return <></>
        }
    }

    useEffect(() => {
        if(params.id) { 
            init(params?.id) 
        } else {
            setReadOnly(false) 
        };
    }, [params])

    return (
        <DashboardContainer processing={processing}>
            <Card style={{ border: '7px' }} className="pt-3 pb-2 mb-3">
                <Stack sx={{ width: '100%' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={application?.app_step || 0} connector={<ColorlibConnector />}>
                        {
                            steps.map((label, key) => (
                                <Step key={key} completed={Boolean(application?.app_step > key)} onClick={() => setStep(key)}>
                                    <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Stack>
            </Card>
            
            {getContent(activeStep || 0)}

        </DashboardContainer>
    )
}

export default NewApplication