import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const Page404 = ({ user }) => {
    return (
        <Container className='p-3 mb-2'>
            <Grid container>
                <Grid item xs={12} md={6} style={{ margin: 'auto' }} textAlign={'center'}>
                    <Link to='/'>
                        <img className='logo' src='/assets/images/aaipsds-logo.png' alt='aaipsds' style={{ height: '100px', textAlign: 'center' }} />
                    </Link>
                    <h3 className='mt-0 mb-1'>Abdulsalami Abubakar Institute for Peace and Sustainable Development Studies (AAIPSDS) Portal</h3>

                    <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/assets/images/404.svg`} alt="404 image" />
                    <h3 className='mb-0'>Page not Found</h3>
                    <p className='mt-05 mb-05'>The resource you are looking for have been moved or does not exist</p>
                    <Link to={`/${user?.userData?.user_type}`}>
                        <Button>Go Back Home</Button>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    )
}


export default connect(({ user}) => ({ user }))(Page404)