import { USER_LOGIN, USER_LOGOUT, USER_UPDATE } from "./constants";
import { loginUser } from "../api/auth";

export const updateUserData = (user) => {
    return async (dispatch) => {
        try {
            await dispatch({
                type: USER_UPDATE,
                payload: user,
            });
            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const signInUser = (values) => {
    return async (dispatch) => {
        try {
            const userData = await loginUser(values);
            localStorage.setItem("aaipsds-user", JSON.stringify(userData));
            await dispatch({
                type: USER_LOGIN,
                payload: userData,
            });
        
            return Promise.resolve(userData);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const signOutUser = () => {
    return async (dispatch) => {
        try {
            localStorage.removeItem("aaipsds-user");
            await dispatch({
                type: USER_LOGOUT
            });
            console.log('signed out')
            window.location.href = '/'
            return Promise.resolve(true);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    };
};