import { $axios } from "./config";

const url = '/internal';

const getUsers = async (email = '', userType = '', accountStatus = '') => {
    return $axios.get(`${url}/users?email=${email}&userType=${userType}&accountStatus=${accountStatus}`);
}

const getUserDetails = async (id) => {
    return $axios.get(`${url}/users/${id}`);
}

const createOrUpdateUser = async (details) => {
    return $axios.post(`${url}/create_or_update_user`, details);
}


const getRoles = async (id = '') => {
    return $axios.get(`${url}/roles/${id}`);
}

const getAllRoles = async () => {
    return $axios.get(`${url}/roles/`);
}


const getPrivileges = async (id = '') => {
    return $axios.get(`${url}/privileges/${id}`);
}

const getAuditReports = async () => {
    return $axios.get(`${url}/audit_report`);
}


const addOrUpdatePrivilege = async (privilegeDetails) => {
    return $axios.post(`${url}/add_or_update_privilege`, privilegeDetails)
}

const addOrUpdateRole = async (roleDetails) => {
    return $axios.post(`${url}/add_or_update_role`, roleDetails)
}

const rolePrivilegeMapping = async (mappingDetails) => {
    return $axios.post(`${url}/role_privilege_mapping`, mappingDetails)
}

const passwordReset = async (details) => {
    return $axios.post(`${url}/password_reset`, details)
}

const emailPasswordReset = async (details) => {
    return $axios.post(`${url}/email_password_reset`, details)
}


export {
    getPrivileges, getRoles, getAllRoles, 
    rolePrivilegeMapping, addOrUpdatePrivilege, addOrUpdateRole, createOrUpdateUser, getUserDetails, getUsers,
    passwordReset, emailPasswordReset, getAuditReports
}