import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Divider } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import { getQualifications } from '../../redux/api/static'
import { Delete } from '@mui/icons-material'

const EducationalDetails = ({ updateApplication, applicationDetails, nextStep, readOnly }) => {
    const defaultVal = {
        institution_name: '',
        programme_name: '',
        duration: '',
        qualification: ''
    };
    const [ formData, setFormData ] = useState({
        education_details: [defaultVal],
    })

    const deleteDetail = (index) => {
        const details = [ ...formData.education_details ];
        setFormData({ ...formData, education_details: details.filter((ft, idx) => idx !== index) })
    }

    const handleChange = ({ value, name}, index) => {
        let details = [ ...formData.education_details ];
        details[index][name] = value
        setFormData({ ...formData, education_details: details })
    }

    useEffect(() => {
        setFormData({
            education_details: (applicationDetails?.education_details?.length > 0) ? applicationDetails?.education_details : [ defaultVal ]
        })
    }, [applicationDetails])

    return (
        <Card className='p-2 pt-3 pb-3'>
            <h2 className="text-center">Educational Details</h2>
            <Formik 
                initialValues={{ ...formData }} 
                enableReinitialize
                onSubmit={async (values) => await updateApplication({ ...applicationDetails, ...formData }, 'education')}
            >
                {
                    ({ isSubmitting, values, errors }) => (
                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Grid container item xs={12} justifyContent='flex-end'>
                                <Button variant='contained' color="primary" disabled={readOnly} onClick={() => setFormData({ ...formData, education_details: [ ...formData.education_details, defaultVal ] })}>
                                    Add
                                </Button>
                            </Grid>
                            {
                                values?.education_details?.map(({ institution_name, duration, programme_name, qualification }, index) => (
                                    <Grid container spacing={2} key={index} alignItems='center' className='mt-05 pb-1' style={{ borderBottom: '1px solid #eee' }}>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <TextInput readOnly={readOnly} valueFirst customChange={(name, value) => handleChange({ name, value }, index)} value={institution_name} errors={errors} id="institution_name" label="Institution name" required placeholder="Enter institution name" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <TextInput readOnly={readOnly} valueFirst customChange={(name, value) => handleChange({ name, value }, index)}  value={programme_name} errors={errors} id="programme_name" label="Course of Study" required placeholder="Enter course" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <TextInput readOnly={readOnly} valueFirst customChange={(name, value) => handleChange({ name, value }, index)}  value={duration} errors={errors} id="duration" label="Duration (Years)" type="number" required placeholder="Enter duration" />
                                        </Grid>
                                        <Grid item xs={11} md={5} lg={2}>
                                            <SelectInput readOnly={readOnly} customChange={(name, value) => handleChange({ name: 'qualification', value }, index)} value={qualification} errors={errors} id="qualification" label="Qualification" required placeholder="Select qualification" 
                                                selectFunction={getQualifications} field='code' fieldDisplay='desc'
                                            />
                                        </Grid>
                                        <Grid item xs={1} className='pt-2' container alignItems={'center'} justifyContent='center'>
                                            { Boolean(values?.education_details?.length > 1 && !readOnly) && <Delete onClick={() => deleteDetail(index)} style={{ cursor: 'pointer' }} color='error' /> }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <Grid container item xs={12} className="mt-1 pr-05 pl-0 text-center" spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Button variant='contained' color={"secondary"} onClick={() => nextStep(1)} fullWidth>
                                        Previous
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>

                                    {
                                        !(readOnly) ?
                                        <Button variant='contained' type='submit' color="primary" fullWidth disabled={values?.education_details?.length === 0}>
                                            { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Update & Proceed' }
                                        </Button>
                                        :
                                        <Button variant='contained' onClick={() => nextStep(3)} color="primary" fullWidth>
                                            Proceed
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Form>
                    )
                } 
            </Formik>
        </Card>
    )
}

export default EducationalDetails