import { Add, Circle } from '@mui/icons-material'
import { Button, capitalize, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DashboardContainer from '../../../components/DashboardContainer'
import { getAllNews } from '../../../redux/api/internal'
import appRoutes from '../../../routes/appRoutes'
import { getColor, getHexColor } from '../../../util'
import LinesEllipsis from 'react-lines-ellipsis'

const News = () => {
    const [news, setNews] = useState([]);
    const [ fetching, setFetching ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()

    const init = async () => {
        setFetching(true)
        try {
            const resp = await getAllNews();
            setNews(resp);
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally{
            setFetching(false)
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <DashboardContainer pageTitle='News' processing={fetching} buttonComponent={
            <Link to={`${appRoutes.staff.resources.news}/new`}>
                <Button startIcon={<Add />} variant="contained">Add News</Button>
            </Link>
        }>
            <Grid container spacing={3}>
                {
                    news.map((n, key) => (
                        <Grid item xs={12} md={6} lg={4} key={key}>
                            <Card style={{ height: "100%" }}>
                                <CardActionArea onClick={() => history.push(`${appRoutes.staff.resources.news}/${n.slug}`)}>
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={n.featured_image}
                                        alt={n.title}
                                    />
                                    <CardContent className='card-content'>
                                        <div>
                                            <h3 className="m-0">
                                                <LinesEllipsis
                                                    text={n.title}
                                                    maxLine='2'
                                                    ellipsis='...'
                                                    trimRight
                                                    basedOn='letters'
                                                />
                                            </h3>
                                            <Typography variant="body2" color="text.secondary" className="f-14 mt-05" style={{ height: '55px', overflow: 'hidden' }}>      
                                                <LinesEllipsis
                                                    text={n.description}
                                                    maxLine='2'
                                                    ellipsis='...'
                                                    trimRight
                                                    basedOn='letters'
                                                />
                                            </Typography>
                                        </div>
                                        <Circle title={capitalize(n?.status || '')} style={{ fill: getHexColor(n.status) }} />
                                    </CardContent>
                                </CardActionArea>
                                {/* <CardActions>
                                    <Button size="small" color="primary">
                                        Edit
                                    </Button>
                                </CardActions> */}
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        </DashboardContainer>
    )
}

export default News