import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import * as Yup from 'yup';
import { getProgrammes, getProgrammesCategories } from '../../redux/api/static'

const ProgrammeDetails = ({ updateApplication, applicationDetails, nextStep,  readOnly }) => {
    const [ programmes, setProgrammes ] = useState([]);
    const [ selectedProg, setSelectedProg ] = useState({});

    const init = async () => {
        try {
            const progs = await getProgrammes();
            setProgrammes(progs)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if(applicationDetails?.id) {
            const prog = programmes.find(({ id }) => String(id) == String(applicationDetails.programme));
            setSelectedProg(prog)
        }
    }, [programmes, applicationDetails])

    return (
        <Card className='p-2 pt-3 pb-3'>
            <h2 className="text-center">Programme Details</h2>
            <Formik 
                initialValues={{ ...applicationDetails, duration: selectedProg?.duration || '', 
                    application_fee: selectedProg?.application_fee || '', course_fee: selectedProg?.course_fee || '',
                    charge: selectedProg?.charge || 0 
                }} 
                enableReinitialize
                validationSchema={Yup.object().shape({
                    programme_category: Yup.string().required('Programme category is required.'),
                    programme: Yup.string().required('Programme is required.'),
                })}
                onSubmit={async (values) => await updateApplication(values, 'programme')}
            >
                {
                    ({ isSubmitting, values, errors, setFieldValue }) => (
                        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={2} alignItems='flex-end'>
                                <Grid item xs={12} md={6}>
                                    <SelectInput errors={errors} 
                                        id="programme_category" 
                                        label="Programme Category" 
                                        required placeholder="Select programme category" 
                                        selectFunction={getProgrammesCategories} 
                                        field='id' 
                                        readOnly={applicationDetails?.app_step > 0 || readOnly}
                                        fieldDisplay='name'
                                        customChange={(name, value) => {
                                            setFieldValue('duration', '')
                                            setFieldValue('application_fee', '')
                                            setFieldValue('course_fee', '')
                                            setFieldValue('charge', 0)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <SelectInput 
                                        errors={errors} 
                                        filterField='programme_category' required 
                                        filterValue={String(values.programme_category)}
                                        options={programmes} fieldDisplay='name' 
                                        readOnly={applicationDetails?.app_step > 0 || readOnly}
                                        // readOnly
                                        field='id' id="programme" label="Programme" placeholder="Select a programme" 
                                        customChange={(name, value) => {
                                            const prog = programmes.find(({ id }) => String(id) == String(value));
                                            // setSelectedProg(prog)
                                            setFieldValue('duration', prog?.duration)
                                            setFieldValue('application_fee', prog?.application_fee)
                                            setFieldValue('course_fee', prog?.course_fee)
                                            setFieldValue('charge', prog?.charge || 0)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="duration" 
                                        label="Programme Duration"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="application_fee" 
                                        label="Programme Application Fee"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="course_fee" 
                                        label="Programme Course Fee"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput 
                                        errors={errors} 
                                        required 
                                        readOnly={true}
                                        id="charge" 
                                        label="Additional Charge"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="text-center">
                                    {
                                        !(applicationDetails?.app_step > 0 || readOnly) ?
                                        <Button variant='contained' type='submit' color="primary" fullWidth>
                                            { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Save & Proceed' }
                                        </Button>
                                        :
                                        <Button variant='contained' onClick={() => nextStep(1)} color="primary" fullWidth>
                                            Proceed
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Form>
                    )
                } 
            </Formik>
        </Card>
    )
}
export default ProgrammeDetails;