import { AccountCircleOutlined, Add, ApprovalOutlined, ArticleOutlined, BadgeOutlined, CancelOutlined, CheckCircleOutlineOutlined, Dashboard, Description, DescriptionOutlined, ErrorOutlineOutlined, EventNoteOutlined, Group, Groups, GroupsOutlined, LibraryBooksOutlined, ListAlt, ListAltOutlined, NewspaperOutlined, PendingActionsOutlined, PermIdentityOutlined, Person, PhotoSizeSelectActualOutlined, PlaylistAddCheckCircleOutlined, Public, School, VerifiedOutlined } from "@mui/icons-material";
import { GiBookCover, GiGraduateCap } from 'react-icons/gi'
import { MdPayment } from 'react-icons/md'
import { BsFileText } from 'react-icons/bs'
import { TbBooks } from 'react-icons/tb'
import appRoutes from './appRoutes'
import store from '../redux/store';


export const applicantMenu = () => {
    return (
        [
            {
                title: 'Dashboard',
                path: appRoutes.applicant.dashboard,
                icon: <Dashboard />,
                id: '',
                active: true
            },
            {
                title: 'All Applications',
                path: appRoutes.applicant.applications,
                icon: <ListAlt />,
                id: '',
                active: true,
                subMenus: [
                    {
                        title: 'New Applications',
                        path: `${appRoutes.applicant.new}`,
                        icon: <DescriptionOutlined />,
                        id: '',
                        active: true
                    },
                    {
                        title: 'All Applications',
                        path: `${appRoutes.applicant.applications}`,
                        icon: <DescriptionOutlined />,
                        id: '',
                        active: true
                    },
                ]
                
            },
        ]
    )
}

export const applicationMenu = (app_step, app_id) => {
    return (
        [
            {
                title: 'Personal Information',
                path: `/application/new`,
                icon: <Person />,
                id: '',
                active: true
            },
            {
                title: 'Educational Information',
                path: `/application/educational-information`,
                icon: <TbBooks />,
                id: '',
                active: true
            },
            {
                title: 'Programme Information',
                path: app_id ? `/application/${app_id}/programme-information` : `/application/programme-information`,
                icon: <GiGraduateCap />,
                id: '',
                check: 'programme-information',
                active: true
            },
            {
                title: 'Application Payment',
                path: `/application/${app_id}/payment`,
                icon: <MdPayment />,
                id: '',
                check: 'payment',
                active: Boolean(app_step >= 4)
            },
            {
                title: 'Review',
                path: `/application/${app_id}/review`,
                icon: <BsFileText />,
                id: '',
                check: 'review',
                active: Boolean(app_step >= 5)
            }
        ]
    )
}

export const studentMenu = () => [
    {
        title: 'Dashboard',
        path: appRoutes.student.dashboard,
        icon: <Dashboard />,
        id: '',
        active: true
    },
    {
        title: 'Profile',
        path: appRoutes.student.profile,
        icon: <AccountCircleOutlined />,
        id: '',
        active: true
    },
    {
        title: 'Results',
        path: appRoutes.student.results,
        icon: <PlaylistAddCheckCircleOutlined />,
        id: '',
        active: true
    },
    {
        title: 'Courses',
        path: appRoutes.student.courses,
        icon: <LibraryBooksOutlined />,
        id: '',
        active: true
    },
    {
        title: 'Fees',
        path: appRoutes.student.fees,
        icon: <ListAltOutlined />,
        id: '',
        active: true
    },
]

export const staffMenu = () => [
    {
        title: 'Dashboard',
        path: appRoutes.staff.dashboard,
        icon: <Dashboard />,
        id: '',
        active: true
    },
    {
        title: 'All Applications',
        path: appRoutes.staff.applications,
        icon: <DescriptionOutlined />,
        id: 'viewApplications',
        active: true,
        subMenus: [
            {
                title: 'All Applications',
                path: `${appRoutes.staff.applications}`,
                icon: <DescriptionOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Initiated Applications',
                path: `${appRoutes.staff.applications}?status=1`,
                icon: <ApprovalOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Submitted Applications',
                path: `${appRoutes.staff.applications}?status=2`,
                icon: <PendingActionsOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Granted Applications',
                path: `${appRoutes.staff.applications}?status=3`,
                icon: <CheckCircleOutlineOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Accepted Applications',
                path: `${appRoutes.staff.applications}?status=4`,
                icon: <VerifiedOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Declined Applications',
                path: `${appRoutes.staff.applications}?status=8`,
                icon: <ErrorOutlineOutlined />,
                id: 'viewApplications',
                active: true
            },
            {
                title: 'Rejected Applications',
                path: `${appRoutes.staff.applications}?status=7`,
                icon: <CancelOutlined />,
                id: 'viewApplications',
                active: true
            },
        ]
        
    },
    {
        title: 'Students',
        path: '/students',
        icon: <Groups />,
        id: 'viewStudents',
        active: true
    },
    {
        title: 'Staff',
        path: 'staff',
        icon: <Group />,
        id: 'viewStaff',
        active: true
    },
    {
        title: 'Payments',
        path: '/payments',
        icon: <Dashboard />,
        id: 'viewPayments',
        active: true
    },
    {
        title: 'Programmes',
        path: '/programmes',
        icon: <Dashboard />,
        id: 'viewProgrammes',
        active: true
    },
    {
        title: 'User Management',
        path: appRoutes.internal.users,
        icon: <BadgeOutlined />,
        id: 'accessUserManagement',
        active: true,
        subMenus: [
            {
                title: 'Users',
                path: appRoutes.internal.users,
                icon: <GroupsOutlined />,
                id: 'accessUserManagement',
                active: true
            },
            {
                title: 'Roles & Privileges',
                path: appRoutes.internal.rolesPrivileges,
                icon: <DescriptionOutlined />,
                id: 'accessUserManagement',
                active: true
            }
        ]
        
    },
    
]

export const getMenu = (type = '') => {
    const user = store?.getState()?.user?.userData || {}
    const step = user?.app_step || 0
    const user_type = type || user?.user_type || ''

    switch(user_type) {
        case 'applicant':
            return applicantMenu(step);
        case 'student': 
            return studentMenu(step);
        case 'staff':
            return staffMenu(step);
        case 'admin':
            return staffMenu(step);
        default:
            return applicantMenu(step)
    }
}