import DashboardContainer from '../../components/DashboardContainer'
import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box, Divider, capitalize, Dialog, DialogContent, DialogActions } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../components/common/TextInput'
import SelectInput from '../../components/common/SelectInput'
import DocumentInput from '../../components/common/DocumentInput'
import { useHistory, useParams } from 'react-router-dom'
import { getCountries, getGenders, getRelationships, getQualifications } from '../../redux/api/static'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import moment from 'moment'
import appRoutes from '../../routes/appRoutes'
import { documents } from './DocumentUpload'
import { completeDetails, getApplicationDetails, updateAdmissionStatus } from '../../redux/api/applicant'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'

const Review = ({ user, applicationDetails }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { id: app_id } = useParams()
    const [ applicationData, setApplicationData ] = useState({})
    const [ userData, setUserData ] = useState({})
    const [ processing, setProcessing ] = useState(false)
    const [ fetching, setFetching ] = useState(false)
    const [ alert, showAlert ] = useState(false)

    const completeApp = async () => {
        try {
            // setProcessing(true)
            (alert == 'complete') ? await completeDetails(applicationData.id) : await updateAdmissionStatus({ app_id: applicationData?.id, status: alert });
            showAlert(false);
            history.push(appRoutes.applicant.applications)
        }
        catch(err) {
            console.log(err)
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            // setProcessing(false)
        }
    }

    const init = async (app_id) => {
        try {
            setFetching(true)
            const resp = await getApplicationDetails(app_id);
            const { payments, ...app } = resp[0]
            const payment = payments?.find(r => r.payment_type === "application");
            setApplicationData({ 
                ...app, 
                payment: {
                    ...payment,
                    status: capitalize(payment?.status || ''),
                    created_at: payment?.created_at ? moment(payment?.created_at).format('DD MMM, YYYY - hh:mm A') : '',
                    payment_date: payment?.payment_date ? moment(payment?.payment_date).format('DD MMM, YYYY - hh:mm A') : ''
                } 
            } || {});
        }
        catch(err) {
            console.log(err)
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setFetching(false)
        }
    }


    useEffect(() => {
        setApplicationData(applicationDetails || {})
    }, [applicationDetails])

    return (
        <>
            <Card className='p-2 pt-3 pb-3'>
                <Formik 
                    initialValues={{ ...applicationData,
                        payment_date: moment(applicationData.payment_date).format('YYYY-MM-DD hh:mm:ss A'), 
                        payment_created_at: moment(applicationData.payment_created_at).format('YYYY-MM-DD hh:mm:ss A') 
                    }} 
                    enableReinitialize
                >
                    {
                        ({ isSubmitting, values, errors }) => (
                            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Grid container spacing={2}>
                                    <>

                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Personal Information</h2>
                                        </Grid>

                                        <Grid item xs={12} container alignItems={'center'} justifyContent={'center'} flexDirection='column'>
                                            <Box 
                                                style={{ width: '250px', height: '250px', border: '1px solid grey', borderRadius: '5px' }}
                                                display='flex' justifyContent={'center'} alignItems='center'
                                            >
                                                <img src={values.passport} alt='passport' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }} />
                                            </Box>
                                        </Grid>
                                    </>

                                    <>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} readOnly id="surname" label="Surname" placeholder="Enter surname" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} readOnly id="first_name" label="First Name" placeholder="Enter first name" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} readOnly id="other_names" label="Other Name(s)" placeholder="Enter other names" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} readOnly id="dob" label="Date of birth" placeholder="Select date" type="date" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <SelectInput errors={errors} id="gender" readOnly label="Gender" required placeholder="Select gender" 
                                                selectFunction={getGenders} field='code' fieldDisplay='desc'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} readOnly id="phone" label="Phone Number" placeholder="Enter phone number" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <SelectInput errors={errors} required readOnly selectFunction={getCountries} fieldDisplay='desc' field='code' id="nationality" label="Nationality" placeholder="Select a country" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <SelectInput errors={errors} required readOnly selectFunction={getCountries} fieldDisplay='desc' field='code' id="country_of_birth" label="Country of Birth" placeholder="Select a country" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <SelectInput errors={errors} readOnly id="english_proficiency" field='code' fieldDisplay='code' label="English Proficiency" options={[{ code: 'high' }, { code: 'medium' }, { code: 'low' }]} placeholder="Select an option" />
                                        </Grid> 
                                        <Grid item xs={12}>
                                            <TextInput errors={errors} readOnly id="address" placeholder="Enter residential address" label="Address" minRows={2} multiline />
                                        </Grid>
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Programme Information</h2>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} id="programme_name" readOnly label="Programme" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} id="programme_category_name" readOnly label="Programme Category"/>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} id="programme_duration" readOnly label="Duration" />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextInput errors={errors} id="programme_category_abbr" readOnly label="Programme Abbreviation"/>
                                        </Grid>
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Educational Information</h2>
                                        </Grid>
                                        {
                                            values?.education_details?.map(({ institution_name, duration, programme_name, qualification }, index) => (
                                                <Grid container spacing={2} key={index} alignItems='center' className='mt-05 pb-1' style={{ borderBottom: '1px solid #eee' }}>
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextInput value={institution_name} readOnly errors={errors} id="institution_name" label="Institution name" required placeholder="Enter institution name" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextInput value={programme_name} readOnly errors={errors} id="programme_name" label="Course of Study" required placeholder="Enter course" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <TextInput value={duration} readOnly errors={errors} id="duration" label="Duration (Years)" type="number" required placeholder="Enter duration" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        <SelectInput value={qualification} readOnly errors={errors} id="qualification" label="Qualification" required placeholder="Select qualification" 
                                                            selectFunction={getQualifications} field='code' fieldDisplay='desc'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Educational Information</h2>
                                        </Grid>
                                        {
                                            documents.map((doc, key) => (
                                                <Grid item xs={12} key={key} md={6}>
                                                    <DocumentInput 
                                                        required={!doc.optional}
                                                        id={doc.id} readOnly
                                                        label={doc.label}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Payment Information</h2>
                                        </Grid>
                                        {
                                            values?.paymentDetails?.map((pt, key) => (
                                                <Grid container item xs={12} spacing={2}>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id={`paymentDetails.[${key}].payment_type`} capitalizeValue readOnly label="Payment Type" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id={`paymentDetails.[${key}].status`} capitalizeValue readOnly label="Status" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id={`paymentDetails.[${key}].amount`} capitalizeValue readOnly label="Amount" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput errors={errors} id={`paymentDetails.[${key}].created_at`}  readOnly label="Initiation Date"/>
                                                    </Grid>
                                                    {
                                                        pt?.remitaResponse &&
                                                        <>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <TextInput errors={errors} id={`paymentDetails.[${key}].rrr`} readOnly label="RRR"/>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <TextInput errors={errors} id={`paymentDetails.[${key}].payment_date`} readOnly label="Payment Date" />
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider className='mt-2' />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <Grid item xs={12} container justifyContent={'center'}>
                                        <Grid item xs={12} md={6} lg={4}>
                                            { 
                                                values.app_status == 1 && 
                                                <Button variant='contained' className="mt-1" color="primary" fullWidth onClick={() => showAlert('complete')}>
                                                    Complete Application
                                                </Button> 
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container spacing={3}>
                                        { 
                                            values.app_status == 3 && 
                                            <Grid item xs={12} md={6}>
                                                <Button variant='contained' className="mt-1" color="primary" fullWidth onClick={() => showAlert('accept')}>
                                                    Accept Admission
                                                </Button> 
                                            </Grid>
                                        }
                                        { 
                                            values.app_status == 3 && 
                                            <Grid item xs={12} md={6}>
                                                <Button variant='contained' className="mt-1" color="error" fullWidth onClick={() => showAlert('decline')}>
                                                    Decline Admission
                                                </Button> 
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    } 
                </Formik>
            </Card>

            <Dialog open={alert}>
                <DialogContent className='p-2'>
                    {
                        (alert == 'complete') ?
                        <img src={`${process.env.PUBLIC_URL}/assets/images/success.png`} style={{ width: 'auto',  height: '250px', display: 'block', margin: 'auto' }} />
                        :
                        (alert == 'accept') ?
                        <CheckCircleOutline color="success" style={{ margin: 'auto', fontSize: '60px', display: 'block' }} />
                        :
                        (alert == 'decline') ?
                        <CancelOutlined color="error" style={{ margin: 'auto', fontSize: '60px', display: 'block' }} />
                        :
                        <></>
                    }


                    <h2 className='mb-0 mt-0 text-center'>{(alert === 'complete') ? 'Complete Application' : (alert == 'accept') ? 'Accept Admission' : (alert == 'decline') ? 'Decline Admission' : ''} </h2>
                    <p className="p-0 text-center" >
                        {
                            (alert === 'complete') ?
                            'Kindly ensure all details are filled in correctly. Once application is submitted, it cannot be edited.'
                            :
                            (alert === 'accept') ?
                            'Once admission is accepted your student information will be generated and sent to you via mail.'
                            :
                            (alert === 'decline') ?
                            'Kindly confirm that you want to forfeit admission. Note that once you decline admission, you cannot accept afterwards.'
                            :
                            ''
                        }
                        
                    </p>
                </DialogContent>
                <DialogActions className='pb-2 pr-2'>
                    <Button variant="contained" color="secondary" disabled={processing} onClick={() => showAlert(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" disabled={processing} onClick={completeApp}
                        color={(alert === 'complete') ? 'success' : (alert === 'accept') ? 'success' : (alert === 'decline') ? 'error' : 'primary'}
                    >
                        { processing ? <CircularProgress color="inherit" size={15} />  : (alert === 'complete') ? 'Submit Application' : (alert === 'accept') ? 'Accept' : (alert === 'decline') ? 'Decline' : '' }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Review;