import React from 'react'

const ErrorBox = ({ errors = [] }) => {
    return (
        <>
            {
                Boolean(typeof(errors) == 'string') ?
                <small className='error'>{errors}</small>
                :
                errors?.map((error, key) => (
                    <small key={key} className='error'>{error}</small>
                ))
            }
        </>
    )
}

export default ErrorBox