import { Button, Container, Drawer, Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import { HiMenuAlt3 } from 'react-icons/hi';

const websiteBase = process.env.REACT_APP_WEBSITE_URL || '';

const LINKS = [
    { label: 'Home', link: '/home' },
    { label: 'Our Programmes', link: '/our-programmes', target: '_blank' },
    { label: 'Help & Support', link: '/contact-us', target: '_blank' },
]

const Nav = () => {
    const md = useMediaQuery({ query: '(min-width: 906px)' })
    const sm = useMediaQuery({ query: '(max-width: 905px)' })
    const [ showMobileMenu, setShowMobileMenu ] = useState(false);

    return (
        <div style={{ boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)", position: 'fixed', width: '100%', backgroundColor: '#fff', zIndex: 99 }}>
            <Container className='pt-05 pb-05'>
                <Grid container alignItems='center' className='links'>
                    <Grid item alignItems='center'>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/aaipsds-logo.png`} style={{ height: '60px', display: 'block', margin: '0 auto' }} />
                    </Grid>
                    <Grid item container flex={1}  alignItems='center' justifyContent='flex-end'>
                        {
                            md ?
                            <>
                                {
                                    LINKS.map(({ link, label, ...props }, key) => (
                                        <a {...props} key={key} href={websiteBase + link} className='mr-1'>{label}</a>
                                    ))
                                }
                                <Link to='/login'>
                                    <Button className='btn' variant='contained'>Login</Button>
                                </Link>
                            </>
                            :
                            <IconButton onClick={() => setShowMobileMenu(true)} color='primary'>
                                <HiMenuAlt3 size={25} />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </Container>

            <Drawer anchor='right' open={showMobileMenu} onClose={() => setShowMobileMenu(false)} PaperProps={{ style: { zIndex: 999, minWidth: '60vw' } }}>
                <Container className='pt-1 mobile-menu'>
                    <Grid item xs={12} container alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12} className='mt-1'>
                            {
                                LINKS.map(({ link, label, ...props }, key) => (
                                    <a {...props} key={key} href={websiteBase + link}>{label}</a>
                                ))
                            }
                            <Link to='/login'>
                                Login
                            </Link>
                            <Link to='/application/new' className='mt-1 mb-1 no-border'>
                                <Button className='btn' fullWidth variant='contained'>Register</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Drawer>
        </div>
    )
}

export default Nav