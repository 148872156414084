import { $axios } from "./config";
import firebaseApp from './firebaseConfig'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage"; 

const url = '/internal';

const getAnalytics = async () => {
    return $axios.get(`${url}/get_analytics`);
}

const getSlug = async (payload) => {
    return $axios.post(`${url}/generate_slug`, payload);
}

export const updateImage = async (file, path) => {
    const storage = getStorage();
    const storageRef = ref(storage, `resources/${path}.png`);
    await uploadBytes(storageRef, file)
    const url = await getDownloadURL(storageRef)

    return url;
}

const getAllNews = async (slug) => {
    return $axios.get(`${url}/all_news/${slug || ''}`);
}

const addOrUpdateNews = async (payload) => {
    return $axios.post(`${url}/add_or_update_news`, payload);
}

const getAllApplications = async (status = '') => {
    return $axios.get(`${url}/all_applications?status=${status}`);
}

const getApplicationDetails = async (app_id = '') => {
    return $axios.get(`${url}/application_details?app_id=${app_id}`);
}

export {
    getAnalytics, getAllNews, addOrUpdateNews, getSlug,
    getAllApplications, getApplicationDetails
}