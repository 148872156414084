import { Button, CircularProgress, Grid, IconButton, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import TextInput from '../components/common/TextInput'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signInUser } from '../redux/actions/userActions'
import { useSnackbar } from 'notistack'
import { sendVerifyEmail } from '../redux/api/auth'
import { passwordReset, emailPasswordReset } from '../redux/api/userMgt'
import { PRIMARY_COLOR } from '../styles/colors'
import appRoutes from '../routes/appRoutes'
import { CheckCircle, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

const Login = ({ signInUser }) => {
    const lg = useMediaQuery({ query: '(min-width: 1200px)' });
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();

    const [ showVerification, setShowVerification ] = useState(null)
    const [ showPassword, setShowPassword ] = useState(false)

    const resetPasswordForm = { oldPassword: '', newPassword: '', conPassword: '' };
    const [ resetAlert, setResetAlert ] = useState(null);
    const [ resetSuccessMsg, setResetSuccessMsg ] = useState('');


    const sendVerification = async () => {
        try {
            await sendVerifyEmail(showVerification);
            enqueueSnackbar('Email sent - Kindly check your mailbox')
            setShowVerification(null)
        }
        catch(err) {
            console.log(err)
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
    }

    const login = async (values, { setErrors }) => {
        try {
            const user = await signInUser(values);
            if(!user?.password_reset) {
                history.push(user.user_type)
            } else if(user?.password_reset) setResetAlert('passwordReset')
        }
        catch(err) {
            setErrors(err?.errors || {})
            if(err?.code == 'UNVERIFIED') {
                setShowVerification(values?.email)
                enqueueSnackbar(err?.errors?.email || err?.message || 'An error occurred', { variant: 'error' })
            } else {
                enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
            }

        }
    }
    
    
    const resetPassword = async (values, { setSubmitting, resetForm, setErrors }) => {
        setSubmitting(true)
        try {
            await passwordReset(values);
            resetForm();
            setResetAlert('success')
            setResetSuccessMsg('Password reset was successfully, Login to continue')
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
            setErrors(err)
        }
        finally {
            setSubmitting(false)
        }
    }

    const emailResetPassword = async (values, { setSubmitting, resetForm, setErrors }) => {
        setSubmitting(true)
        try {
            await emailPasswordReset(values);
            resetForm();
            setResetAlert('success')
            setResetSuccessMsg('A password has been generated and sent to your email address. You will be prompted to reset this password upon login')
        }
        catch (err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
            setErrors(err)
        }
        finally {
            setSubmitting(false)
        }
    }


    return (
        <div>
            <Grid container style={{ minHight: '100vh' }}>
                {
                    lg &&
                    <Grid item xs={0} lg={6} className='login-bg text-white text-center' container justifyContent={'center'} alignItems='center'>
                        <div style={{ padding: '10%' }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/aaipsds-logo.png`} style={{ height: '150px', display: 'block', margin: '0 auto' }} />

                            <h2>Abdulsalami Abubakar Institute for Peace <br /> and Sustainable Development Studies</h2>
                            <p>Abdulsalami Abubakar Institute for Peace and Sustainable Development Studies (AAIPSDS) aims to provide an excellent platform for intellectual growth and capacity for stability and development of national and global communities.</p>
                        </div>
                    </Grid>
                }
                <Grid item xs={12} lg={6} container flexDirection={'column'} style={{ minHeight: '100vh' }}>
                    <Grid flex={1} container flexDirection={'column'} justifyContent={'center'} alignItems='center' style={{ width: lg ? '80%' : '90%', margin: '-3rem auto 0', padding: '0 1rem 3rem' }}>
                        { !lg && <img src={`${process.env.PUBLIC_URL}/assets/images/aaipsds-logo.png`} style={{ height: '70px', display: 'block', margin: '0 auto' }} /> }
                        <h2 className='mb-0 mt-05'>Account Login</h2>
                        <p className='mt-05 mb-1'>Welcome back, kindly provide your credentials to proceed</p>

                        <Formik 
                            initialValues={{ email: '', password: '' }}
                            onSubmit={login}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().required('Password is required.'),
                                // .min(8, 'Password field must be more than 8 characters').matches(
                                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                // )
                                email: Yup.string().email('Value must be a valid email address').required('Email Address is required.')
                            })}
                        >
                            {
                                ({ isSubmitting }) => (
                                    <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Grid container spacing={3} >
                                            <Grid item xs={12}>
                                                <TextInput id="email" label="Email Address" required />
                                                {
                                                    showVerification &&
                                                    <small className='text-primary cursor-pointer' onClick={sendVerification}>Resend Verification Email</small>
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput id="password" label="Password" required 
                                                    type={showPassword ? 'text' : 'password'}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    edge="end" size='small'
                                                                >
                                                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button type="submit" variant='contained' color="primary" fullWidth disabled={isSubmitting}>
                                                    { isSubmitting ? <CircularProgress size={15} color='inherit' /> : 'Login' }
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Link to='/applicant/new'>
                                                    <Button variant='outlined' color="primary" fullWidth disabled={isSubmitting}>
                                                        New Application
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Link to='#' onClick={() => setResetAlert('emailReset')} className='mt-1 text-right' >Forgot Password</Link>
                                    </Form>
                                )
                            } 
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>



            <Dialog
                open={Boolean(resetAlert)}
                keepMounted
                disableEscapeKeyDown
            >
                <DialogContent>
                    {
                        resetAlert === 'emailReset' ?
                        <>
                            <h2 className='mb-0 mt-0'>Email Password Reset</h2>
                            <p className='mt-05'> If user exists, an email will be sent to you with a temporary password.</p>

                            <Formik
                                initialValues={{ email: '' }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().required('Email Address is required.'),
                                })}
                                onSubmit={emailResetPassword}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Grid container spacing={3} paddingY={0}>
                                            <Grid item xs={12}>
                                                <TextInput id='email' required label='Email Address' placeholder='Enter email address' />
                                            </Grid>
                                        </Grid>
                                        
                                        <DialogActions className='no-padding pb-0 pt-1'>
                                            <Button onClick={() => { setResetAlert(null) }} variant='contained' color='secondary'>Cancel</Button>
                                            <Button disabled={isSubmitting} type='submit' variant='contained'>
                                                {
                                                    isSubmitting ? 
                                                    <CircularProgress size={15} color='inherit' />
                                                    :
                                                    'Reset'
                                                }
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        
                        </>
                        :
                        resetAlert === 'passwordReset' ?
                        <> 
                            <h2 className='mb-0'>Password Reset</h2>
                            <p className='mt-05'>Your account requires a password reset to proceed.</p>

                            <Formik
                                initialValues={{ ...resetPasswordForm }}
                                validationSchema={Yup.object().shape({
                                    newPassword: Yup.string().min(8, 'Password field must be more than 8 characters').matches(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                    ).required('Password is required.'),
                                    oldPassword: Yup.string().required('Old Password is required.'),
                                    conPassword: Yup.string().required('Confirm Password is required.').oneOf([Yup.ref('newPassword'), null], 'Password does not match New Password'),
                                })}
                                onSubmit={resetPassword}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Grid container spacing={3} paddingY={2}>
                                            <Grid item xs={12}>
                                                <TextInput id='oldPassword' type='password' required label='Old Password' placeholder='Enter old password' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput id='newPassword' type='password' required label='New Password' placeholder='Enter new password' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput id='conPassword' type='password' required label='Confirm Password' placeholder='Enter password confirmation' />
                                            </Grid>
                                        </Grid>
                                        
                                        <DialogActions className='no-padding pb-1'>
                                            <Button onClick={() => { setResetAlert(null) }} variant='contained' color='secondary'>Cancel</Button>
                                            <Button type='submit' disabled={isSubmitting} variant='contained'>
                                                {
                                                    isSubmitting ? 
                                                    <CircularProgress size={15} color='inherit' />
                                                    :
                                                    'Reset'
                                                }
                                            </Button>
                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        
                        </>
                        :
                        resetAlert === 'success' &&
                        <> 
                            <CheckCircle style={{ color: PRIMARY_COLOR, margin: '0 auto', display: 'block', fontSize: '75px' }} />
                            <h2 className='mb-0 mt-05 text-center'>Successfully</h2>
                            <p className='mt-05 text-center'>{resetSuccessMsg}</p>

                            <Button style={{ margin: '0 auto 1rem', display: 'block', minWidth: '50%' }} variant='contained' color="secondary" onClick={() => { setResetAlert(null); setResetSuccessMsg(null) }}>Close</Button>
                    
                        </>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signInUser }, dispatch);
}
export default connect(({ }) => ({ }), mapDispatchToProps)(Login)