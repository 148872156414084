import DashboardContainer from '../../../components/DashboardContainer'
import React, { useEffect, useRef, useState } from 'react'
import { Button, CircularProgress, Grid, Card, Box, Divider, capitalize, Dialog, DialogContent, DialogActions } from '@mui/material'
import { Form, Formik } from 'formik'
import TextInput from '../../../components/common/TextInput'
import SelectInput from '../../../components/common/SelectInput'
import { useHistory, useParams } from 'react-router-dom'
import { getApplicationStatuses, getCountries, getGenders, getRelationships } from '../../../redux/api/static'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import moment from 'moment'
import appRoutes from '../../../routes/appRoutes'
import { bindActionCreators } from 'redux'
import { completeDetails, updateAdmissionStatus, updatePassport, updatePersonalDetails } from '../../../redux/api/applicant'
import { getApplicationDetails } from '../../../redux/api/internal'
import { documents } from '../../applicant/DocumentUpload'
import DocumentInput from '../../../components/common/DocumentInput'
import { CheckCircleOutline, CloseOutlined } from '@mui/icons-material'

const ApplicationDetails = ({ user }) => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { appId } = useParams()
    const [ applicationData, setApplicationData ] = useState({})
    const [ userData, setUserData ] = useState({})
    const [ processing, setProcessing ] = useState(false)
    const [ fetching, setFetching ] = useState(false)
    const [ alert, showAlert ] = useState(false)

    const completeApp = async () => {
        try {
            setProcessing(true)
            await updateAdmissionStatus({ app_id: applicationData?.app_id || applicationData?.id || '', status: alert }) 
            showAlert(false);
            history.push(appRoutes.staff.applications)
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setProcessing(false)
        }
    }

    const init = async (appId) => {
        try {
            setFetching(true)
            const resp = await getApplicationDetails(appId);
            const { payments, ...app } = resp;
            const payment = payments?.find(r => r.payment_type === "application");
            setApplicationData({ 
                ...app, 
                payment: {
                    ...payment,
                    status: capitalize(payment?.status || ''),
                    created_at: payment?.created_at ? moment(payment?.created_at).format('DD MMM, YYYY - hh:mm A') : '',
                    payment_date: payment?.payment_date ? moment(payment?.payment_date).format('DD MMM, YYYY - hh:mm A') : ''
                } 
            } || {});
            console.log({ 
                ...app, 
                payment: {
                    ...payment,
                    status: capitalize(payment?.status || ''),
                    created_at: payment?.created_at ? moment(payment?.created_at).format('DD MMM, YYYY - hh:mm A') : '',
                    payment_date: payment?.payment_date ? moment(payment?.payment_date).format('DD MMM, YYYY - hh:mm A') : ''
                } 
            } )
        }
        catch(err) {
            enqueueSnackbar(err?.message || 'An error occurred', { variant: 'error' })
        }
        finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        if(appId) {
            init(appId)
        }
    }, [])

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user])

    return (
        <DashboardContainer pageTitle='Application Details' processing={fetching}>
            <Card className='p-2 pt-3 pb-3'>
                <Formik 
                    initialValues={{ ...applicationData, payment_date: moment(applicationData.payment_date).format('YYYY-MM-DD hh:mm:ss A'), payment_created_at: moment(applicationData.payment_created_at).format('YYYY-MM-DD hh:mm:ss A') }} 
                    enableReinitialize
                >
                    {
                        ({ isSubmitting, values, errors }) => (
                            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Grid container spacing={2}>
                                    <>

                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Personal Information</h2>
                                        </Grid>

                                        <Grid item xs={12} container alignItems={'center'} justifyContent={'center'} flexDirection='column'>
                                            <Box 
                                                style={{ width: '250px', height: '250px', border: '1px solid grey', borderRadius: '5px' }}
                                                display='flex' justifyContent={'center'} alignItems='center'
                                            >
                                                <img src={values?.passport} alt='passport' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }} />
                                            </Box>
                                        </Grid>
                                    </>

                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput readOnly id="surname" label="Surname" placeholder="Enter surname" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput readOnly id="first_name" label="First Name" placeholder="Enter first name" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput readOnly id="other_names" label="Other Name(s)" placeholder="Enter other names" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput readOnly id="dob" label="Date of birth" placeholder="Select date" type="date" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <SelectInput readOnly id="gender" label="Gender" placeholder="Select gender" 
                                                selectFunction={getGenders} field='code' fieldDisplay='desc'
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput readOnly id="phone" label="Phone Number" placeholder="Enter phone number" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <SelectInput readOnly selectFunction={getCountries} field='code' fieldDisplay='desc' id="nationality" label="Nationality" placeholder="Select a country" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <SelectInput readOnly selectFunction={getCountries} field='code' fieldDisplay='desc' id="country_of_birth" label="Country of Birth" placeholder="Select a country" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <SelectInput readOnly id="english_proficiency" field='code' fieldDisplay='code' label="English Proficiency" options={[{ code: 'high' }, { code: 'medium' }, { code: 'low' }]} placeholder="Select an option" />
                                        </Grid> 
                                        <Grid item xs={12}>
                                            <TextInput readOnly id="address" placeholder="Enter residential address" label="Address" minRows={2} multiline />
                                        </Grid>
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Programme Information</h2>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput id="app_no" readOnly label="Application Number" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput id="programme_name" readOnly label="Programme" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput id="programme_category_name" readOnly label="Programme Category"/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput id="programme_duration" readOnly label="Duration" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <TextInput id="programme_category_abbr" readOnly label="Programme Abbreviation"/>
                                        </Grid>
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Educational Information</h2>
                                        </Grid>
                                        {
                                            values?.education_details?.map((dt, key)=> (
                                                <Grid container item xs={12} key={key} spacing={2}>
                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                        <TextInput readOnly id={`education_details[${key}]['institution_name']`} label="Institution name" placeholder="Enter institution name"  />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                        <TextInput id={`education_details[${key}]['programme_name']`} readOnly label="Programme Name"/>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                        <TextInput id={`education_details[${key}]['qualification']`} readOnly label="Qualification" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                                        <TextInput id={`education_details[${key}]['duration']`} readOnly label="Duration"/>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </>
{/* 
                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Document Information</h2>
                                        </Grid>
                                        <Grid item xs={12} container spacing={2}>
                                            {
                                                documents.map((doc, key) => (
                                                    <Grid item xs={12} key={key} sm={6} md={4} lg={3}>
                                                        <DocumentInput 
                                                            id={doc.id} readOnly
                                                            label={doc.label}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </> */}

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>

                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Application Payment Information</h2>
                                        </Grid>
                                        {
                                            values?.paymentDetails?.map((pt, key) => (
                                                <Grid container item xs={12} spacing={2}>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id={`paymentDetails.[${key}].payment_type`} capitalizeValue readOnly label="Payment Type" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id={`paymentDetails.[${key}].status`} capitalizeValue readOnly label="Status" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id={`paymentDetails.[${key}].amount`} capitalizeValue readOnly label="Amount" />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={4}>
                                                        <TextInput id={`paymentDetails.[${key}].created_at`}  readOnly label="Initiation Date"/>
                                                    </Grid>
                                                    {
                                                        pt?.remitaResponse &&
                                                        <>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <TextInput id={`paymentDetails.[${key}].rrr`} readOnly label="RRR"/>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} lg={4}>
                                                                <TextInput id={`paymentDetails.[${key}].payment_date`} readOnly label="Payment Date" />
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <Divider className='mt-2' />
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                        
                                    </>

                                    <Grid item xs={12}>
                                        <Divider className='mt-2' />
                                    </Grid>


                                    <>
                                        <Grid item xs={12}>
                                            <h2 className='mb-0 mt-1 text-center'>Admission Status</h2>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectInput readOnly id="app_status" field='id' fieldDisplay='desc' label="Application Status" selectFunction={getApplicationStatuses} placeholder="Select an option"  />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={6} md={4} container justifyContent={'flex-end'} flexDirection='column'>
                                            <Button variant='contained' className="mt-1" color="info" fullWidth onClick={() => showAlert(true)}>
                                                Request Information
                                            </Button>
                                        </Grid> */}
                                        {
                                            (values?.app_status == 2) &&
                                            <Grid item xs={12} sm={6} md={4} container justifyContent={'flex-end'} flexDirection='column'>
                                                <Button variant='contained' className="mt-1" color="success" fullWidth onClick={() => showAlert('grant')}>
                                                    Grant
                                                </Button>
                                            </Grid>
                                        }
                                        {
                                            (values?.app_status == 2 || values?.app_status == 3) &&
                                            <Grid item xs={12} sm={6} md={4} container justifyContent={'flex-end'} flexDirection='column'>
                                                <Button variant='contained' className="mt-1" color="error" fullWidth onClick={() => showAlert('reject')}>
                                                    Decline
                                                </Button>
                                            </Grid>
                                        }
                                    </>
                                </Grid>
                            </Form>
                        )
                    } 
                </Formik>
            </Card>

            <Dialog open={alert}>
                <DialogContent className='p-2'>
                    {
                        (alert == 'grant') ?
                        <CheckCircleOutline color="success" style={{ margin: 'auto', fontSize: '60px', display: 'block' }} />
                        :
                        (alert == 'reject') ?
                        <CloseOutlined color="error" style={{ margin: 'auto', fontSize: '60px', display: 'block' }} />
                        :
                        <></>
                    }
                    <h2 className='mb-0 mt-05 text-center'>{ alert == 'grant' ? 'Grant Admission' : (alert == 'reject') ? 'Reject Application' : '' }</h2>
                    <p className="pt-0 pb-0 text-center">
                        {
                            alert == 'grant' ?
                            'Kindly ensure all details filled meet requirements for admission. Once details are confirmed, applicant will be notified on grant of admission.'
                            :
                            (alert == 'reject') ?
                            'Kindly ensure all details filled meet requirements for decline. Once details are confirmed, applicant will be notified on decline of admission.'
                            :
                            ''
                        }
                    </p>
                </DialogContent>
                <DialogActions className='pb-2 pr-2 text-center' style={{ justifyContent: 'center' }}>
                    <Button variant="contained" color="secondary" disabled={processing} onClick={() => showAlert(false)}>
                        Cancel 
                    </Button>
                    <Button variant="contained" color={(alert == 'grant') ? 'success' : 'error'} disabled={processing} onClick={completeApp}>
                        { processing ? <CircularProgress color="inherit" size={15} />  : (alert == 'grant') ? 'Grant Admission' : (alert == 'reject') ? 'Reject Application' : '' }
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardContainer>
    )
}

export default connect(({ user }) => ({ user }), null)(ApplicationDetails)