export const PRIMARY_COLOR = "#2C6AD3"
export const SECONDARY_COLOR = "#0D2439"
export const TERTIARY_COLOR = "#BF712D"
export const LIGHT_GREY = "#F8FBFF"

export const SUCCESS_COLOR = "#27AE60"
export const GREEN_COLOR = "green"
export const WARNING_COLOR = "#F2C94C"
export const ERROR_COLOR = "#F74343"

export const BLACK_COLOR = "#000000"
export const GREY_COLOR = "#2E2E2E"
export const WHITE_COLOR = "#FFFFFF"